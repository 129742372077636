<template>
  <div id="lorem-container">
    <div class="lorem-textarea-container">
      <textarea id="lorem-textarea" v-model="loremText" cols="120" rows="15"></textarea>
    </div>
    <div class="lorem-controls-container">
      <div class="input-group">
        <span class="input-group-title">Sentences</span>
        <input
          ref="loremSentencesInput"
          class="input-group-value"
          id="lorem-sentences-input"
          type="number"
          min="1"
          max="1000"
          value="1" />
      </div>
      <div class="lorem-buttons-container">
        <button @click="fetchLorem()" class="btn green" id="generate-lorem-btn">
          <i class="icon bi-card-text"></i>
          <span>Generate lorem</span>
        </button>
        <button @click="copyLorem" class="btn">
          <i class="icon bi-clipboard"></i>
          <span>Copy</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'LoremView',

  setup() {
    const loremText = ref(null);
    const loremSentencesInput = ref(null);

    const fetchLorem = (init = false) => {
      let sentences;
      if (!init) sentences = loremSentencesInput.value.value;

      if (!sentences || isNaN(sentences) || sentences <= 0) sentences = 1;
      if (sentences > 1000) sentences = 1000;
      sentences = Number(sentences);

      if (!init) loremSentencesInput.value.value = sentences;
      $axios
        .get(`/genlorem?sentences=${sentences}`)
        .then(res => {
          loremText.value = res.data;
        })
        .catch(err => console.error(err));
    };

    const copyLorem = () => {
      navigator.clipboard.writeText(loremText.value);
    };

    fetchLorem('init');

    return { loremText, loremSentencesInput, fetchLorem, copyLorem };
  }
};
</script>

<style>
.lorem-textarea-container {
  display: flex;
}

#lorem-textarea {
  width: 100%;
  resize: vertical;
  overflow-x: hidden;
  font-family: inherit;
  position: relative; /* breaks fonts without this (https://bugs.chromium.org/p/chromium/issues/detail?id=1423434) */
}

.lorem-controls-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

#lorem-sentences-input {
  text-align: center;
}

#generate-lorem-btn {
  margin: 0px 4px 0px 8px;
}

.lorem-controls-container .btn .icon {
  margin-right: 4px;
}

#lorem-container {
  max-width: 600px;
}

.lorem-buttons-container {
  display: flex;
}

@media screen and (max-width: 440px) {
  .lorem-controls-container {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  #generate-lorem-btn {
    margin-left: 0;
  }
}
</style>
