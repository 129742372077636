export default [
  {
    class: 'bi-0-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-0-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-0-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-0-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-1-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-1-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-1-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-1-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-123',
    tags: ['numbers'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-2-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-2-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-2-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-2-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-3-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-3-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-3-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-3-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-4-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-4-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-4-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-4-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-5-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-5-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-5-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-5-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-6-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-6-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-6-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-6-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-7-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-7-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-7-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-7-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-8-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-8-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-8-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-8-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-9-circle',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-9-circle-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-9-square',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-9-square-fill',
    tags: ['number', 'numeral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-activity',
    tags: ['pulse', 'heartbeat', 'rhythm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-airplane',
    tags: ['flight', 'flying', 'plane', 'air', 'airport', 'aircraft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-airplane-engines',
    tags: ['flight', 'flying', 'plane', 'air', 'airport', 'aircraft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-airplane-engines-fill',
    tags: ['flight', 'flying', 'plane', 'air', 'airport', 'aircraft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-airplane-fill',
    tags: ['flight', 'flying', 'plane', 'air', 'airport', 'aircraft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-alarm',
    tags: ['alarm', 'clock', 'time'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-alarm-fill',
    tags: ['alarm', 'clock', 'time'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-alexa',
    tags: ['social', 'assistant'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-align-bottom',
    tags: ['space', 'align', 'distribute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-align-center',
    tags: ['space', 'align', 'distribute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-align-end',
    tags: ['space', 'align', 'distribute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-align-middle',
    tags: ['space', 'align', 'distribute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-align-start',
    tags: ['space', 'align', 'distribute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-align-top',
    tags: ['space', 'align', 'distribute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-alipay',
    tags: ['payments'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-alphabet',
    tags: ['letters', 'abc'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-alphabet-uppercase',
    tags: ['letters', 'abc'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-alt',
    tags: ['key', 'alt', 'option'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-amazon',
    tags: ['aws'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-amd',
    tags: ['radeon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-android',
    tags: ['google', 'droid'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-android2',
    tags: ['google', 'droid'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-app',
    tags: ['app', 'application', 'ios', 'android', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-app-indicator',
    tags: ['app', 'application', 'ios', 'android', 'notification', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-apple',
    tags: ['aapl', 'mac', 'macintosh'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-archive',
    tags: ['box', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-archive-fill',
    tags: ['box', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-90deg-down',
    tags: ['arrow', 'right-angle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-90deg-left',
    tags: ['arrow', 'right-angle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-90deg-right',
    tags: ['arrow', 'right-angle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-90deg-up',
    tags: ['arrow', 'right-angle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-bar-down',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-bar-left',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-bar-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-bar-up',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-clockwise',
    tags: ['arrow', 'right', 'spin', 'turn', 'around', 'round', 'rotate', 'refresh', 'reload', 'redo'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-counterclockwise',
    tags: ['arrow', 'left', 'spin', 'turn', 'around', 'round', 'rotate', 'undo'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-left-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-left-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-left-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-left-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-right-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-right-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-right-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-right-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-left',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-short',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-down-up',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-left',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-left-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-left-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-left-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-left-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-left-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-left-short',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-repeat',
    tags: ['arrow', 'spin', 'turn', 'around', 'round', 'rotate', 'sync'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-return-left',
    tags: ['arrow', 'return'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-return-right',
    tags: ['arrow', 'return'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-right-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-right-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-right-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-right-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-right-short',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-through-heart',
    tags: ['cupid', 'love', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-through-heart-fill',
    tags: ['cupid', 'love', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-left-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-left-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-left-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-left-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-right-circle',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-right-circle-fill',
    tags: ['arrow', 'circle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-right-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-right-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-square',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-square-fill',
    tags: ['arrow', 'square'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-left',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrow-up-short',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows',
    tags: ['arrow', 'resize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-angle-contract',
    tags: ['arrow', 'resize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-angle-expand',
    tags: ['arrow', 'resize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-collapse',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-collapse-vertical',
    tags: ['arrow', 'resize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-expand',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-expand-vertical',
    tags: ['arrow', 'resize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-fullscreen',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-move',
    tags: ['arrow', 'cursor', 'move'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-arrows-vertical',
    tags: ['arrow', 'resize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-aspect-ratio',
    tags: ['size', 'resize', 'crop', 'dimensions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-aspect-ratio-fill',
    tags: ['size', 'resize', 'crop', 'dimensions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-asterisk',
    tags: ['asterisks', 'star'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-at',
    tags: ['mention', 'sign'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-award',
    tags: ['prize', 'rosette'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-award-fill',
    tags: ['prize', 'rosette'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-back',
    tags: ['backward', 'layer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack-fill',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack2',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack2-fill',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack3',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack3-fill',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack4',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backpack4-fill',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backspace',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backspace-fill',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backspace-reverse',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-backspace-reverse-fill',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-3d',
    tags: ['3d', 'display', 'dimension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-3d-fill',
    tags: ['3d', 'display', 'dimension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-4k',
    tags: ['4k', 'display', 'resolution', 'retina'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-4k-fill',
    tags: ['4k', 'display', 'resolution', 'retina'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-8k',
    tags: ['4k', 'display', 'resolution', 'retina'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-8k-fill',
    tags: ['4k', 'display', 'resolution', 'retina'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-ad',
    tags: ['advertisement'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-ad-fill',
    tags: ['advertisement'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-ar',
    tags: ['augmented', 'reality', 'ar'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-ar-fill',
    tags: ['augmented', 'reality', 'ar'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-cc',
    tags: ['closed', 'captioning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-cc-fill',
    tags: ['closed', 'captioning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-hd',
    tags: ['display', 'resolution', 'high', 'definition'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-hd-fill',
    tags: ['display', 'resolution', 'high', 'definition'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-sd',
    tags: ['display', 'resolution', 'standard', 'definition'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-sd-fill',
    tags: ['display', 'resolution', 'standard', 'definition'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-tm',
    tags: ['trademark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-tm-fill',
    tags: ['trademark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-vo',
    tags: ['voiceover', 'accessibility'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-vo-fill',
    tags: ['voiceover', 'accessibility'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-vr',
    tags: ['virtual', 'reality', 'vr'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-vr-fill',
    tags: ['virtual', 'reality', 'vr'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-wc',
    tags: ['wash', 'closet', 'wc'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-badge-wc-fill',
    tags: ['wash', 'closet', 'wc'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag',
    tags: ['shopping', 'cart', 'purchase', 'buy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-check',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-check-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-dash',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-dash-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-fill',
    tags: ['shopping', 'cart', 'purchase', 'buy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-heart',
    tags: ['shopping', 'cart', 'purchase', 'buy', 'valentine', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-heart-fill',
    tags: ['shopping', 'cart', 'purchase', 'buy', 'valentine', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-plus',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-plus-fill',
    tags: ['shopping', 'add', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-x',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bag-x-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-balloon',
    tags: ['birthday'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-balloon-fill',
    tags: ['birthday'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-balloon-heart',
    tags: ['birthday', 'valentine', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-balloon-heart-fill',
    tags: ['birthday', 'valentine', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ban',
    tags: ['false', 'not', 'allowed'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ban-fill',
    tags: ['false', 'not', 'allowed'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bandaid',
    tags: ['bandage', 'health'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bandaid-fill',
    tags: ['bandage', 'health'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bank',
    tags: ['money', 'finance', 'banking', 'market', 'temple'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bank2',
    tags: ['money', 'finance', 'banking', 'market', 'temple'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bar-chart',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bar-chart-fill',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bar-chart-line',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bar-chart-line-fill',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bar-chart-steps',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-basket',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-basket-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-basket2',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-basket2-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-basket3',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-basket3-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-battery',
    tags: ['power', 'charge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-battery-charging',
    tags: ['power', 'charge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-battery-full',
    tags: ['power', 'charge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-battery-half',
    tags: ['power', 'charge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-behance',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bell',
    tags: ['notification', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bell-fill',
    tags: ['notification', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bell-slash',
    tags: ['notification', 'silenced', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bell-slash-fill',
    tags: ['notification', 'silenced', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bezier',
    tags: ['graphics', 'vector', 'pen'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bezier2',
    tags: ['graphics', 'vector', 'pen'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bicycle',
    tags: ['bike', 'riding', 'bicycling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bing',
    tags: ['search', 'microsoft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-binoculars',
    tags: ['distance', 'view', 'search'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-binoculars-fill',
    tags: ['distance', 'view', 'search'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-blockquote-left',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-blockquote-right',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bluetooth',
    tags: ['wireless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-body-text',
    tags: ['content'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-book',
    tags: ['novel', 'read', 'magazine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-book-fill',
    tags: ['novel', 'read', 'magazine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-book-half',
    tags: ['novel', 'read', 'magazine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-check',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-check-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-dash',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-dash-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-heart',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-heart-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-plus',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-plus-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-star',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-star-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-x',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmark-x-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmarks',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookmarks-fill',
    tags: ['reading', 'book', 'label', 'tag', 'category'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bookshelf',
    tags: ['shelf'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-boombox',
    tags: ['music'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-boombox-fill',
    tags: ['music'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bootstrap',
    tags: ['bootstrap'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bootstrap-fill',
    tags: ['bootstrap'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bootstrap-reboot',
    tags: ['bootstrap'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-all',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-bottom',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-center',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-inner',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-left',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-middle',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-outer',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-right',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-style',
    tags: ['borders', 'wysiwyg'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-top',
    tags: ['borders'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-border-width',
    tags: ['borders', 'wysiwyg'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bounding-box',
    tags: ['text', 'shape', 'resize', 'dimensions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bounding-box-circles',
    tags: ['text', 'shape', 'resize', 'dimensions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box',
    tags: ['cardboard', 'package', 'cube'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-down-left',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-down-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-down',
    tags: ['arrow', 'download', 'save'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-down',
    tags: ['arrow', 'upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-down-left',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-down-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-left',
    tags: ['arrow', 'login', 'signin', 'enter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-right',
    tags: ['arrow', 'login', 'signin', 'enter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-up',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-up-left',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-in-up-right',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-left',
    tags: ['arrow', 'logout', 'signout', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-right',
    tags: ['arrow', 'logout', 'signout', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-up',
    tags: ['arrow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-up-left',
    tags: ['arrow', 'external', 'link'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-arrow-up-right',
    tags: ['arrow', 'external', 'link'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-fill',
    tags: ['cardboard', 'package'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-seam',
    tags: ['cardboard', 'package'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box-seam-fill',
    tags: ['cardboard', 'package'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box2',
    tags: ['cardboard', 'package', 'cube'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box2-fill',
    tags: ['cardboard', 'package', 'cube'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box2-heart',
    tags: ['cardboard', 'package', 'cube', 'gift', 'valentine', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-box2-heart-fill',
    tags: ['cardboard', 'package', 'cube', 'gift', 'valentine', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-boxes',
    tags: ['cardboard', 'package', 'cube'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-braces',
    tags: ['text', 'type', 'code', 'developer', 'development', 'software'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-braces-asterisk',
    tags: ['text', 'type', 'code', 'developer', 'development', 'software'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bricks',
    tags: ['wall', 'firewall'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-briefcase',
    tags: ['business', 'bag', 'baggage'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-briefcase-fill',
    tags: ['business', 'bag', 'baggage'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-alt-high',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-alt-high-fill',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-alt-low',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-alt-low-fill',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-high',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-high-fill',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-low',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brightness-low-fill',
    tags: ['brightness', 'sun', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brilliance',
    tags: ['photo', 'editing', 'image', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-broadcast',
    tags: ['radio', 'radio', 'wave', 'amplify', 'wavelength'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-broadcast-pin',
    tags: ['radio', 'radio', 'wave', 'amplify', 'wavelength', 'podcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-browser-chrome',
    tags: ['google', 'webkit', 'blink'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-browser-edge',
    tags: ['microsoft', 'webkit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-browser-firefox',
    tags: ['gecko'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-browser-safari',
    tags: ['webkit', 'apple'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brush',
    tags: ['paint', 'art'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-brush-fill',
    tags: ['paint', 'art'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bucket',
    tags: ['tool', 'pail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bucket-fill',
    tags: ['tool', 'pail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bug',
    tags: ['insect', 'error'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bug-fill',
    tags: ['insect', 'error'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-add',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-check',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-dash',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-down',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-exclamation',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-add',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-check',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-dash',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-down',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-exclamation',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-gear',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-lock',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-slash',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-up',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-fill-x',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-gear',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-lock',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-slash',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-up',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-building-x',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-buildings',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-buildings-fill',
    tags: ['company', 'enterprise', 'organization', 'office', 'business'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bullseye',
    tags: ['target'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bus-front',
    tags: ['public', 'transit', 'commute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-bus-front-fill',
    tags: ['public', 'transit', 'commute'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-c-circle',
    tags: ['copyright'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-c-circle-fill',
    tags: ['copyright'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-c-square',
    tags: ['copyright'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-c-square-fill',
    tags: ['copyright'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cake',
    tags: ['birthday', 'celebrate', 'dessert'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cake-fill',
    tags: ['birthday', 'celebrate', 'dessert'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cake2',
    tags: ['birthday', 'celebrate', 'dessert'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cake2-fill',
    tags: ['birthday', 'celebrate', 'dessert'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calculator',
    tags: ['calculator', 'math'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calculator-fill',
    tags: ['calculator', 'math'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-check',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-check-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-date',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-date-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-day',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-day-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-event',
    tags: ['date', 'time', 'event', 'invite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-event-fill',
    tags: ['date', 'time', 'event', 'invite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-heart',
    tags: ['date', 'time', 'month', 'valentine', 'date'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-heart-fill',
    tags: ['date', 'time', 'month', 'valentine', 'date'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-minus',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-minus-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-month',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-month-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-plus',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-plus-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-range',
    tags: ['dates', 'timeline', 'duration'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-range-fill',
    tags: ['dates', 'timeline', 'duration'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-week',
    tags: ['dates', 'timeline', 'duration', 'week'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-week-fill',
    tags: ['dates', 'timeline', 'duration', 'week'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-x',
    tags: ['date', 'time', 'month', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar-x-fill',
    tags: ['date', 'time', 'month', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-check',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-check-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-date',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-date-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-day',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-day-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-event',
    tags: ['date', 'time', 'event', 'invite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-event-fill',
    tags: ['date', 'time', 'event', 'invite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-heart',
    tags: ['date', 'time', 'month', 'valentine', 'date'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-heart-fill',
    tags: ['date', 'time', 'month', 'valentine', 'date'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-minus',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-minus-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-month',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-month-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-plus',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-plus-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-range',
    tags: ['dates', 'timeline', 'duration'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-range-fill',
    tags: ['dates', 'timeline', 'duration'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-week',
    tags: ['dates', 'timeline', 'duration', 'week'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-week-fill',
    tags: ['dates', 'timeline', 'duration', 'week'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-x',
    tags: ['date', 'time', 'month', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar2-x-fill',
    tags: ['date', 'time', 'month', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3-event',
    tags: ['date', 'time', 'event', 'invite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3-event-fill',
    tags: ['date', 'time', 'event', 'invite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3-fill',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3-range',
    tags: ['dates', 'timeline', 'duration'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3-range-fill',
    tags: ['dates', 'timeline', 'duration'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3-week',
    tags: ['dates', 'timeline', 'duration', 'week'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar3-week-fill',
    tags: ['dates', 'timeline', 'duration', 'week'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar4',
    tags: ['date', 'time', 'month'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar4-event',
    tags: ['date', 'time', 'event', 'invite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar4-range',
    tags: ['dates', 'timeline', 'duration'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-calendar4-week',
    tags: ['dates', 'timeline', 'duration', 'week'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera',
    tags: ['photos', 'photography'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera2',
    tags: ['photos', 'photography'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera-fill',
    tags: ['photos', 'photography'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera-reels',
    tags: ['av', 'video', 'film'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera-reels-fill',
    tags: ['av', 'video', 'film'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera-video',
    tags: ['av', 'video', 'film'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera-video-fill',
    tags: ['av', 'video', 'film'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera-video-off',
    tags: ['av', 'video', 'film'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-camera-video-off-fill',
    tags: ['av', 'video', 'film'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-capslock',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-capslock-fill',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-capsule',
    tags: ['rx', 'pills', 'capsules', 'medicine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-capsule-pill',
    tags: ['rx', 'pills', 'capsules', 'medicine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-car-front',
    tags: ['automobile', 'automotive', 'auto', 'sedan', 'drive', 'driving', 'vehicle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-car-front-fill',
    tags: ['automobile', 'automotive', 'auto', 'sedan', 'drive', 'driving', 'vehicle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-card-checklist',
    tags: ['note', 'card', 'notecard'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-card-heading',
    tags: ['note', 'card', 'notecard'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-card-image',
    tags: ['note', 'card', 'notecard'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-card-list',
    tags: ['note', 'card', 'notecard'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-card-text',
    tags: ['note', 'card', 'notecard', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-down',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-down-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-down-square',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-down-square-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-left',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-left-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-left-square',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-left-square-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-right',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-right-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-right-square',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-right-square-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-up',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-up-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-up-square',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-caret-up-square-fill',
    tags: ['caret', 'arrow', 'triangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-check',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-check-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-dash',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-dash-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-plus',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-plus-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-x',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart-x-fill',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart2',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart3',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cart4',
    tags: ['shopping', 'checkout', 'check', 'cart', 'basket', 'bag'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cash',
    tags: ['money', 'bills', 'funds', 'wallet', 'currency'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cash-coin',
    tags: ['money', 'finance', 'banking', 'currency'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cash-stack',
    tags: ['money', 'bills', 'funds', 'wallet', 'currency'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cassette',
    tags: ['tape', 'music', 'audio'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cassette-fill',
    tags: ['tape', 'music', 'audio'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cast',
    tags: ['airplay', 'project', 'stream', 'display'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cc-circle',
    tags: ['creative', 'commons'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cc-circle-fill',
    tags: ['creative', 'commons'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cc-square',
    tags: ['creative', 'commons'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cc-square-fill',
    tags: ['creative', 'commons'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-dots',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-dots-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-heart',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-heart-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-dots',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-dots-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-heart',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-heart-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-quote',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-quote-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-text',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-left-text-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-quote',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-quote-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-dots',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-dots-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-heart',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-heart-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-quote',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-quote-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-text',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-right-text-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-dots',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-dots-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'typing', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-heart',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-heart-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'valentine', 'romance', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-quote',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-quote-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'quote', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-text',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-square-text-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-text',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chat-text-fill',
    tags: ['chat', 'bubble', 'text', 'message', 'comment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check',
    tags: ['checkmark', 'confirm', 'done'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check-all',
    tags: ['checkmark', 'todo', 'done', 'select'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check-circle',
    tags: ['checkmark', 'confirm', 'done'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check-circle-fill',
    tags: ['checkmark', 'confirm', 'done'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check-lg',
    tags: ['checkmark', 'confirm', 'done'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check-square',
    tags: ['checkmark', 'confirm', 'done'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check-square-fill',
    tags: ['checkmark', 'confirm', 'done'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check2',
    tags: ['checkmark', 'todo', 'select', 'done', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check2-all',
    tags: ['checkmark', 'todo', 'select', 'done', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check2-circle',
    tags: ['checkmark', 'todo', 'select', 'done', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-check2-square',
    tags: ['checkmark', 'todo', 'select', 'done', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-bar-contract',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-bar-down',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-bar-expand',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-bar-left',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-bar-right',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-bar-up',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-compact-down',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-compact-left',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-compact-right',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-compact-up',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-contract',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-double-down',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-double-left',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-double-right',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-double-up',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-down',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-expand',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-left',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-right',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-chevron-up',
    tags: ['chevron'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-circle',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-circle-fill',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-circle-half',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-slash-circle',
    tags: ['shape', 'stop', 'ban', 'false'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-circle-square',
    tags: ['graphics', 'vector', 'merge', 'layers'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-check',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-check-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-data',
    tags: ['copy', 'paste', 'data', 'analytics', 'graph', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-data-fill',
    tags: ['copy', 'paste', 'data', 'analytics', 'graph', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-heart',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-heart-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-minus',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-minus-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-plus',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-plus-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-pulse',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-x',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard-x-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-check',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-check-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-data',
    tags: ['copy', 'paste', 'data', 'analytics', 'graph', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-data-fill',
    tags: ['copy', 'paste', 'data', 'analytics', 'graph', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-heart',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-heart-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-minus',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-minus-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-plus',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-plus-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-pulse',
    tags: ['copy', 'paste', 'heartrate'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-pulse-fill',
    tags: ['copy', 'paste', 'heartrate'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-x',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clipboard2-x-fill',
    tags: ['copy', 'paste'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clock',
    tags: ['time'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clock-fill',
    tags: ['time'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clock-history',
    tags: ['time', 'history'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud',
    tags: ['weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-arrow-down',
    tags: ['download'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-arrow-down-fill',
    tags: ['download'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-arrow-up',
    tags: ['upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-arrow-up-fill',
    tags: ['upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-check',
    tags: ['checkmark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-check-fill',
    tags: ['checkmark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-download',
    tags: ['arrow', 'save'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-download-fill',
    tags: ['arrow', 'save'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-drizzle',
    tags: ['storm', 'rain'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-drizzle-fill',
    tags: ['storm', 'rain'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-fill',
    tags: ['weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-fog',
    tags: ['foggy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-fog-fill',
    tags: ['foggy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-fog2',
    tags: ['foggy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-fog2-fill',
    tags: ['foggy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-hail',
    tags: ['storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-hail-fill',
    tags: ['storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-haze',
    tags: ['smog'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-haze-fill',
    tags: ['smog'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-haze2',
    tags: ['smog'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-haze2-fill',
    tags: ['smog'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-lightning',
    tags: ['thunder', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-lightning-fill',
    tags: ['thunder', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-lightning-rain',
    tags: ['thunder', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-lightning-rain-fill',
    tags: ['thunder', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-minus',
    tags: ['subtract'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-minus-fill',
    tags: ['subtract'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-moon',
    tags: ['cloudy', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-moon-fill',
    tags: ['cloudy', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-plus',
    tags: ['add', 'new', 'upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-plus-fill',
    tags: ['add', 'new', 'upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-rain',
    tags: ['cloud', 'rainstorm', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-rain-fill',
    tags: ['cloud', 'rainstorm', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-rain-heavy',
    tags: ['cloud', 'rainstorm', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-rain-heavy-fill',
    tags: ['cloud', 'rainstorm', 'storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-slash',
    tags: ['cloud'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-slash-fill',
    tags: ['cloud'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-sleet',
    tags: ['cloud', 'blizzard', 'flurries'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-sleet-fill',
    tags: ['cloud', 'blizzard', 'flurries'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-snow',
    tags: ['cloud', 'blizzard', 'flurries'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-snow-fill',
    tags: ['cloud', 'blizzard', 'flurries'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-sun',
    tags: ['cloudy', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-sun-fill',
    tags: ['cloudy', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-upload',
    tags: ['cloud'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloud-upload-fill',
    tags: ['cloud'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clouds',
    tags: ['clouds', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-clouds-fill',
    tags: ['clouds', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloudy',
    tags: ['clouds', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cloudy-fill',
    tags: ['clouds', 'overcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-code',
    tags: ['text', 'type', 'code', 'developer', 'development', 'software'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-code-slash',
    tags: ['text', 'type', 'code', 'developer', 'development', 'software'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-code-square',
    tags: ['text', 'type', 'code', 'developer', 'development', 'software', 'preformatted'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-coin',
    tags: ['money', 'finance', 'banking', 'currency'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-collection',
    tags: ['library', 'group'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-collection-fill',
    tags: ['library', 'group'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-collection-play',
    tags: ['library', 'group', 'play'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-collection-play-fill',
    tags: ['library', 'group', 'play'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-columns',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-columns-gap',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-command',
    tags: ['key', 'mac'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-compass',
    tags: ['direction', 'map', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-compass-fill',
    tags: ['direction', 'map', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cone',
    tags: ['construction', 'warning', 'safety'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cone-striped',
    tags: ['construction', 'warning', 'safety'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-controller',
    tags: ['game', 'gaming', 'video-game'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cookie',
    tags: ['dessert'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-copy',
    tags: ['paste', 'clone', 'cut', 'duplicate'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cpu',
    tags: ['processor', 'chip', 'computer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cpu-fill',
    tags: ['processor', 'chip', 'computer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-credit-card',
    tags: ['debit', 'card', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-credit-card-2-back',
    tags: ['debit', 'card', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-credit-card-2-back-fill',
    tags: ['debit', 'card', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-credit-card-2-front',
    tags: ['debit', 'card', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-credit-card-2-front-fill',
    tags: ['debit', 'card', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-credit-card-fill',
    tags: ['debit', 'card', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-crop',
    tags: ['crop'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-crosshair',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-crosshair2',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cup',
    tags: ['mug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cup-fill',
    tags: ['mug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cup-hot',
    tags: ['mug', 'steam', 'coffee', 'tea'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cup-hot-fill',
    tags: ['mug', 'steam', 'coffee', 'tea'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cup-straw',
    tags: ['mug', 'glass', 'drink'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-currency-bitcoin',
    tags: ['money', 'finance', 'crypto'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-currency-dollar',
    tags: ['money', 'finance', 'usd'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-currency-euro',
    tags: ['money', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-currency-exchange',
    tags: ['money', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-currency-pound',
    tags: ['money', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-currency-rupee',
    tags: ['money', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-currency-yen',
    tags: ['money', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cursor',
    tags: ['pointer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cursor-fill',
    tags: ['pointer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-cursor-text',
    tags: ['text', 'type', 'cursor'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash-circle',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash-circle-dotted',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash-circle-fill',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash-lg',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash-square',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash-square-dotted',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dash-square-fill',
    tags: ['minus'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-add',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-check',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-dash',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-down',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-exclamation',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-add',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-check',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-dash',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-down',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-exclamation',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-gear',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-lock',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-slash',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-up',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-fill-x',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-gear',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-lock',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-slash',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-up',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-database-x',
    tags: ['server', 'data'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-device-hdd',
    tags: ['drive', 'hard', 'drive'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-device-hdd-fill',
    tags: ['drive', 'hard', 'drive'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-device-ssd',
    tags: ['solid', 'state', 'drive'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-device-ssd-fill',
    tags: ['solid', 'state', 'drive'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-diagram-2',
    tags: ['node', 'diagram', 'sitemap', 'children', 'org', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-diagram-2-fill',
    tags: ['node', 'diagram', 'sitemap', 'children', 'org', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-diagram-3',
    tags: ['node', 'diagram', 'sitemap', 'children', 'org', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-diagram-3-fill',
    tags: ['node', 'diagram', 'sitemap', 'children', 'org', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-diamond',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-diamond-fill',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-diamond-half',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-1',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-1-fill',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-2',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-2-fill',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-3',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-3-fill',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-4',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-4-fill',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-5',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-5-fill',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-6',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dice-6-fill',
    tags: ['dice', 'die', 'games', 'gaming', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-disc',
    tags: ['cd', 'compact', 'disc', 'bluray', 'dvd'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-disc-fill',
    tags: ['cd', 'compact', 'disc', 'bluray', 'dvd'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-discord',
    tags: ['social', 'chat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-display',
    tags: ['monitor', 'external'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-display-fill',
    tags: ['monitor', 'external'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-displayport',
    tags: ['video', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-displayport-fill',
    tags: ['video', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-distribute-horizontal',
    tags: ['space', 'align'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-distribute-vertical',
    tags: ['space', 'align'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-door-closed',
    tags: ['door', 'logout', 'signout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-door-closed-fill',
    tags: ['door', 'logout', 'signout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-door-open',
    tags: ['door', 'login', 'signin'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-door-open-fill',
    tags: ['door', 'login', 'signin'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dot',
    tags: ['middot'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-download',
    tags: ['arrow', 'network', 'save'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dpad',
    tags: ['gaming', 'controller', 'direction'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dpad-fill',
    tags: ['gaming', 'controller', 'direction'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dribbble',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-dropbox',
    tags: ['dropbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-droplet',
    tags: ['water-drop', 'paint', 'ink', 'liquid'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-droplet-fill',
    tags: ['water-drop', 'paint', 'ink', 'liquid'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-droplet-half',
    tags: ['water-drop', 'paint', 'ink', 'liquid'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-duffle',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-duffle-fill',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ear',
    tags: ['hearing', 'sound', 'listen'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ear-fill',
    tags: ['hearing', 'sound', 'listen'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-earbuds',
    tags: ['headphones'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-easel',
    tags: ['paint', 'draw', 'art', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-easel-fill',
    tags: ['paint', 'draw', 'art', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-easel2',
    tags: ['paint', 'draw', 'art', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-easel2-fill',
    tags: ['paint', 'draw', 'art', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-easel3',
    tags: ['paint', 'draw', 'art', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-easel3-fill',
    tags: ['paint', 'draw', 'art', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-egg',
    tags: ['food'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-egg-fill',
    tags: ['food'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-egg-fried',
    tags: ['food'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eject',
    tags: ['disc', 'cd', 'dvd'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eject-fill',
    tags: ['disc', 'cd', 'dvd'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-angry',
    tags: ['emoticon', 'anger', 'upset'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-angry-fill',
    tags: ['emoticon', 'anger', 'upset'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-astonished',
    tags: ['emoticon', 'surprised'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-astonished-fill',
    tags: ['emoticon', 'surprised'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-dizzy',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-dizzy-fill',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-expressionless',
    tags: ['emoticon', 'neutral', 'unphased'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-expressionless-fill',
    tags: ['emoticon', 'neutral', 'unphased'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-frown',
    tags: ['emoticon', 'sad'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-frown-fill',
    tags: ['emoticon', 'sad'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-grimace',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-grimace-fill',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-grin',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-grin-fill',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-heart-eyes',
    tags: ['emoticon', 'heart', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-heart-eyes-fill',
    tags: ['emoticon', 'heart', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-kiss',
    tags: ['emoticon', 'heart', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-kiss-fill',
    tags: ['emoticon', 'heart', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-laughing',
    tags: ['emoticon', 'happy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-laughing-fill',
    tags: ['emoticon', 'happy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-neutral',
    tags: ['emoticon', 'expressionless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-neutral-fill',
    tags: ['emoticon', 'expressionless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-smile',
    tags: ['emoticon', 'happy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-smile-fill',
    tags: ['emoticon', 'happy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-smile-upside-down',
    tags: ['emoticon', 'sarcasm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-smile-upside-down-fill',
    tags: ['emoticon', 'sarcasm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-sunglasses',
    tags: ['emoticon', 'cool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-sunglasses-fill',
    tags: ['emoticon', 'cool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-surprise',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-surprise-fill',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-tear',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-tear-fill',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-wink',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-emoji-wink-fill',
    tags: ['emoticon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-arrow-down',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-arrow-down-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-arrow-up',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-arrow-up-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-at',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-at-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-check',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-check-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-dash',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-dash-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-exclamation',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-exclamation-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-heart',
    tags: ['email', 'message', 'mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-heart-fill',
    tags: ['email', 'message', 'mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-open',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-open-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-open-heart',
    tags: ['email', 'message', 'mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-open-heart-fill',
    tags: ['email', 'message', 'mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-paper',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-paper-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-paper-heart',
    tags: ['email', 'message', 'mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-paper-heart-fill',
    tags: ['email', 'message', 'mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-plus',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-plus-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-slash',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-slash-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-x',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-envelope-x-fill',
    tags: ['email', 'message', 'mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eraser',
    tags: ['erase', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eraser-fill',
    tags: ['erase', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-escape',
    tags: ['esc', 'quit', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ethernet',
    tags: ['internet', 'connection', 'port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ev-front',
    tags: ['car', 'automobile', 'automotive', 'auto', 'sedan', 'drive', 'driving', 'electric', 'vehicle', 'charging'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ev-front-fill',
    tags: ['car', 'automobile', 'automotive', 'auto', 'sedan', 'drive', 'driving', 'electric', 'vehicle', 'charging'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ev-station',
    tags: ['charging', 'electric', 'vehicle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ev-station-fill',
    tags: ['charging', 'electric', 'vehicle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-circle',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-circle-fill',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-diamond',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-diamond-fill',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-lg',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-octagon',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-octagon-fill',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-square',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-square-fill',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-triangle',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclamation-triangle-fill',
    tags: ['alert', 'warning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exclude',
    tags: ['graphics', 'vector', 'merge', 'layers'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-explicit',
    tags: ['r18'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-explicit-fill',
    tags: ['r18'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-exposure',
    tags: ['photo', 'editing', 'image', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eye',
    tags: ['eyeball', 'look', 'see'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eye-fill',
    tags: ['eyeball', 'look', 'see'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eye-slash',
    tags: ['eyeball', 'look', 'see'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eye-slash-fill',
    tags: ['eyeball', 'look', 'see'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eyedropper',
    tags: ['color', 'picker'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-eyeglasses',
    tags: ['eyeball', 'look', 'see', 'glasses', 'reading'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-facebook',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fan',
    tags: ['fan', 'vent', 'airflow'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fast-forward',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fast-forward-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fast-forward-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fast-forward-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fast-forward-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fast-forward-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-feather',
    tags: ['bird', 'flight', 'light'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-feather2',
    tags: ['bird', 'flight', 'light'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file',
    tags: ['doc', 'document', 'page'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-arrow-down',
    tags: ['doc', 'document', 'download'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-arrow-down-fill',
    tags: ['doc', 'document', 'download'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-arrow-up',
    tags: ['doc', 'document', 'upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-arrow-up-fill',
    tags: ['doc', 'document', 'upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-bar-graph',
    tags: ['doc', 'document', 'data', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-bar-graph-fill',
    tags: ['doc', 'document', 'data', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-binary',
    tags: ['doc', 'document', 'binary', 'source'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-binary-fill',
    tags: ['doc', 'document', 'binary', 'source'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-break',
    tags: ['doc', 'document', 'page-break'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-break-fill',
    tags: ['doc', 'document', 'page-break'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-check',
    tags: ['doc', 'document', 'check', 'verified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-check-fill',
    tags: ['doc', 'document', 'check', 'verified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-code',
    tags: ['doc', 'document', 'code', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-code-fill',
    tags: ['doc', 'document', 'code', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-diff',
    tags: ['doc', 'document', 'version', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-diff-fill',
    tags: ['doc', 'document', 'version', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark',
    tags: ['doc', 'document', 'page'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-arrow-down',
    tags: ['doc', 'document', 'download'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-arrow-down-fill',
    tags: ['doc', 'document', 'download'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-arrow-up',
    tags: ['doc', 'document', 'upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-arrow-up-fill',
    tags: ['doc', 'document', 'upload'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-bar-graph',
    tags: ['doc', 'document', 'data', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-bar-graph-fill',
    tags: ['doc', 'document', 'data', 'chart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-binary',
    tags: ['doc', 'document', 'binary', 'source'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-binary-fill',
    tags: ['doc', 'document', 'binary', 'source'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-break',
    tags: ['doc', 'document', 'page-break'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-break-fill',
    tags: ['doc', 'document', 'page-break'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-check',
    tags: ['doc', 'document', 'check', 'verified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-check-fill',
    tags: ['doc', 'document', 'check', 'verified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-code',
    tags: ['doc', 'document', 'code', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-code-fill',
    tags: ['doc', 'document', 'code', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-diff',
    tags: ['doc', 'document', 'version', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-diff-fill',
    tags: ['doc', 'document', 'version', 'development'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-easel',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-easel-fill',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-excel',
    tags: ['doc', 'document', 'spreadsheet', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-excel-fill',
    tags: ['doc', 'document', 'spreadsheet', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-fill',
    tags: ['doc', 'document', 'page'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-font',
    tags: ['ttf', 'otf'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-font-fill',
    tags: ['ttf', 'otf'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-image',
    tags: ['photo', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-image-fill',
    tags: ['photo', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-lock',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-lock-fill',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-lock2',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-lock2-fill',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-medical',
    tags: ['doc', 'document', 'medical', 'hospital', 'health'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-medical-fill',
    tags: ['doc', 'document', 'medical', 'hospital', 'health'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-minus',
    tags: ['doc', 'document', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-minus-fill',
    tags: ['doc', 'document', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-music',
    tags: ['doc', 'document', 'music', 'audio', 'playlist', 'songs'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-music-fill',
    tags: ['doc', 'document', 'music', 'audio', 'playlist', 'songs'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-pdf',
    tags: ['doc', 'document', 'adobe', 'acrobat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-pdf-fill',
    tags: ['doc', 'document', 'adobe', 'acrobat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-person',
    tags: ['doc', 'document', 'personal', 'cv', 'resume', 'about'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-person-fill',
    tags: ['doc', 'document', 'personal', 'cv', 'resume', 'about'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-play',
    tags: ['video', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-play-fill',
    tags: ['video', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-plus',
    tags: ['doc', 'document', 'add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-plus-fill',
    tags: ['doc', 'document', 'add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-post',
    tags: ['doc', 'document', 'post'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-post-fill',
    tags: ['doc', 'document', 'post'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-ppt',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-ppt-fill',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-richtext',
    tags: ['text', 'doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-richtext-fill',
    tags: ['text', 'doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-ruled',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-ruled-fill',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-slides',
    tags: ['presentation', 'keynote', 'powerpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-slides-fill',
    tags: ['presentation', 'keynote', 'powerpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-spreadsheet',
    tags: ['doc', 'document', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-spreadsheet-fill',
    tags: ['doc', 'document', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-text',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-text-fill',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-word',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-word-fill',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-x',
    tags: ['document', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-x-fill',
    tags: ['document', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-zip',
    tags: ['doc', 'document', 'zip', 'archive', 'compress'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-earmark-zip-fill',
    tags: ['doc', 'document', 'zip', 'archive', 'compress'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-easel',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-easel-fill',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-excel',
    tags: ['doc', 'document', 'spreadsheet', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-excel-fill',
    tags: ['doc', 'document', 'spreadsheet', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-fill',
    tags: ['doc', 'document', 'page'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-font',
    tags: ['ttf', 'otf'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-font-fill',
    tags: ['ttf', 'otf'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-image',
    tags: ['photo', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-image-fill',
    tags: ['photo', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-lock',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-lock-fill',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-lock2',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-lock2-fill',
    tags: ['lock', 'private', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-medical',
    tags: ['doc', 'document', 'medical', 'hospital', 'health'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-medical-fill',
    tags: ['doc', 'document', 'medical', 'hospital', 'health'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-minus',
    tags: ['doc', 'document', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-minus-fill',
    tags: ['doc', 'document', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-music',
    tags: ['doc', 'document', 'music', 'audio', 'playlist', 'songs'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-music-fill',
    tags: ['doc', 'document', 'music', 'audio', 'playlist', 'songs'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-pdf',
    tags: ['doc', 'document', 'adobe', 'acrobat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-pdf-fill',
    tags: ['doc', 'document', 'adobe', 'acrobat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-person',
    tags: ['doc', 'document', 'personal', 'cv', 'resume', 'about'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-person-fill',
    tags: ['doc', 'document', 'personal', 'cv', 'resume', 'about'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-play',
    tags: ['video', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-play-fill',
    tags: ['video', 'present'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-plus',
    tags: ['doc', 'document', 'add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-plus-fill',
    tags: ['doc', 'document', 'add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-post',
    tags: ['doc', 'document', 'post'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-post-fill',
    tags: ['doc', 'document', 'post'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-ppt',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-ppt-fill',
    tags: ['slides', 'presentation', 'powerpoint', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-richtext',
    tags: ['doc', 'document', 'richtext'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-richtext-fill',
    tags: ['doc', 'document', 'richtext'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-ruled',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-ruled-fill',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-slides',
    tags: ['presentation', 'keynote', 'powerpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-slides-fill',
    tags: ['presentation', 'keynote', 'powerpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-spreadsheet',
    tags: ['doc', 'document', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-spreadsheet-fill',
    tags: ['doc', 'document', 'excel', 'table'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-text',
    tags: ['doc', 'document', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-text-fill',
    tags: ['doc', 'document', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-word',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-word-fill',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-x',
    tags: ['document', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-x-fill',
    tags: ['document', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-zip',
    tags: ['doc', 'document', 'zip', 'archive', 'compress'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-file-zip-fill',
    tags: ['doc', 'document', 'zip', 'archive', 'compress'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-files',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-files-alt',
    tags: ['doc', 'document'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-aac',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-ai',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-bmp',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-cs',
    tags: ['file', 'file', 'type', 'extension', 'code'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-css',
    tags: ['file', 'file', 'type', 'extension', 'code', 'stylesheet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-csv',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-doc',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-docx',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-exe',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-gif',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-heic',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-html',
    tags: ['file', 'file', 'type', 'extension', 'code', 'html'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-java',
    tags: ['file', 'file', 'type', 'extension', 'code', 'java'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-jpg',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-js',
    tags: ['file', 'file', 'type', 'extension', 'code', 'javascript'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-json',
    tags: ['file', 'file', 'type', 'extension', 'code', 'javascript'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-jsx',
    tags: ['file', 'file', 'type', 'extension', 'code', 'javascript', 'react'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-key',
    tags: ['file', 'file', 'type', 'extension', 'keynote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-m4p',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-md',
    tags: ['file', 'file', 'type', 'extension', 'code', 'markdown'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-mdx',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-mov',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-mp3',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-mp4',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-otf',
    tags: ['file', 'file', 'type', 'extension', 'font', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-pdf',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-php',
    tags: ['file', 'file', 'type', 'extension', 'code'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-png',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-ppt',
    tags: ['file', 'file', 'type', 'extension', 'powerpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-pptx',
    tags: ['file', 'file', 'type', 'extension', 'powerpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-psd',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-py',
    tags: ['file', 'file', 'type', 'extension', 'code', 'python'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-raw',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-rb',
    tags: ['file', 'file', 'type', 'extension', 'code', 'ruby'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-sass',
    tags: ['file', 'file', 'type', 'extension', 'code', 'css', 'stylesheet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-scss',
    tags: ['file', 'file', 'type', 'extension', 'code', 'css', 'stylesheet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-sh',
    tags: ['file', 'file', 'type', 'extension', 'code', 'shell'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-sql',
    tags: ['file', 'file', 'type', 'extension', 'mysql'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-svg',
    tags: ['file', 'file', 'type', 'extension', 'code'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-tiff',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-tsx',
    tags: ['file', 'file', 'type', 'extension', 'code', 'typescript'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-ttf',
    tags: ['file', 'file', 'type', 'extension', 'font', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-txt',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-wav',
    tags: ['file', 'file', 'type', 'extension'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-woff',
    tags: ['file', 'file', 'type', 'extension', 'font', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-xls',
    tags: ['file', 'file', 'type', 'extension', 'spreadsheet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-xlsx',
    tags: ['file', 'file', 'type', 'extension', 'spreadsheet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-xml',
    tags: ['file', 'file', 'type', 'extension', 'code'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filetype-yml',
    tags: ['file', 'file', 'type', 'extension', 'code', 'yaml'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-film',
    tags: ['video', 'movie'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filter',
    tags: ['sort'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filter-circle',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filter-circle-fill',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filter-left',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filter-right',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filter-square',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-filter-square-fill',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fingerprint',
    tags: ['touch', 'ID', 'secure', 'biometric'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fire',
    tags: ['burning', 'smoke'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-flag',
    tags: ['report'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-flag-fill',
    tags: ['report'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-floppy',
    tags: ['save', 'storage', 'disk'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-floppy-fill',
    tags: ['save', 'storage', 'disk'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-floppy2',
    tags: ['save', 'storage', 'disk'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-floppy2-fill',
    tags: ['save', 'storage', 'disk'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-flower1',
    tags: ['plant', 'bloom', 'flower'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-flower2',
    tags: ['plant', 'bloom', 'flower'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-flower3',
    tags: ['plant', 'bloom', 'flower'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder',
    tags: ['directory'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder-check',
    tags: ['directory', 'check', 'verified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder-fill',
    tags: ['directory'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder-minus',
    tags: ['directory', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder-plus',
    tags: ['directory', 'delete', 'add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder-symlink',
    tags: ['directory', 'symbolic-link'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder-symlink-fill',
    tags: ['directory', 'symbolic-link'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder-x',
    tags: ['directory', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder2',
    tags: ['directory'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-folder2-open',
    tags: ['directory'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fonts',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-forward',
    tags: ['mail', 'email'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-forward-fill',
    tags: ['mail', 'email'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-front',
    tags: ['forward', 'layer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fuel-pump',
    tags: ['gas', 'petrol', 'fuel', 'gas', 'station'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fuel-pump-diesel',
    tags: ['gas', 'petrol', 'fuel', 'gas', 'station'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fuel-pump-diesel-fill',
    tags: ['gas', 'petrol', 'fuel', 'gas', 'station'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fuel-pump-fill',
    tags: ['gas', 'petrol', 'fuel', 'gas', 'station'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fullscreen',
    tags: ['window', 'maximize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-fullscreen-exit',
    tags: ['window', 'minimize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-funnel',
    tags: ['sort', 'filter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-funnel-fill',
    tags: ['sort', 'filter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gear',
    tags: ['tool', 'settings', 'preferences'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gear-fill',
    tags: ['tool', 'settings', 'preferences'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gear-wide',
    tags: ['tool', 'settings', 'preferences'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gear-wide-connected',
    tags: ['tool', 'settings', 'preferences'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gem',
    tags: ['shape', 'diamond'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gender-ambiguous',
    tags: ['gender', 'identity'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gender-female',
    tags: ['gender', 'identity'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gender-male',
    tags: ['gender', 'identity'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gender-neuter',
    tags: ['gender', 'identity'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gender-trans',
    tags: ['gender', 'identity'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-geo',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-geo-alt',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-geo-alt-fill',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-geo-fill',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gift',
    tags: ['present', 'gift'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gift-fill',
    tags: ['present', 'gift'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-git',
    tags: ['version', 'control', 'vcs', 'github', 'version'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-github',
    tags: ['social', 'microsoft', 'vcs', 'git'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gitlab',
    tags: ['social', 'vcs', 'git'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-globe',
    tags: ['world', 'translate', 'global', 'international'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-globe-americas',
    tags: ['geography', 'earth', 'world', 'map'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-globe-asia-australia',
    tags: ['geography', 'earth', 'world', 'map'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-globe-central-south-asia',
    tags: ['geography', 'earth', 'world', 'map'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-globe-europe-africa',
    tags: ['geography', 'earth', 'world', 'map'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-globe2',
    tags: ['world', 'translate', 'global', 'international'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-google',
    tags: ['social', 'search'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-google-play',
    tags: ['app', 'store', 'apps', 'store', 'android'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-gpu-card',
    tags: ['video', 'output', 'graphics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-graph-down',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-graph-down-arrow',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-graph-up',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-graph-up-arrow',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-1x2',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-1x2-fill',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-3x2',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-3x2-gap',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-3x2-gap-fill',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-3x3',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-3x3-gap',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-3x3-gap-fill',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grid-fill',
    tags: ['grid', 'layout'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grip-horizontal',
    tags: ['drag', 'grab'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-grip-vertical',
    tags: ['drag', 'grab'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-h-circle',
    tags: ['hospital', 'helicopter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-h-circle-fill',
    tags: ['hospital', 'helicopter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-h-square',
    tags: ['hospital', 'helicopter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-h-square-fill',
    tags: ['hospital', 'helicopter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hammer',
    tags: ['tool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-index',
    tags: ['hand', 'pointer', 'cursor'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-index-fill',
    tags: ['hand', 'pointer', 'cursor'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-index-thumb',
    tags: ['hand', 'pointer', 'cursor'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-index-thumb-fill',
    tags: ['hand', 'pointer', 'cursor'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-thumbs-down',
    tags: ['hand', 'pointer', 'thumbs-down', '-1'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-thumbs-down-fill',
    tags: ['hand', 'pointer', 'thumbs-down', '-1'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-thumbs-up',
    tags: ['hand', 'pointer', 'thumbs-up', '+1'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hand-thumbs-up-fill',
    tags: ['hand', 'pointer', 'thumbs-up', '+1'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-handbag',
    tags: ['purse', 'tote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-handbag-fill',
    tags: ['purse', 'tote'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hash',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd-fill',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd-network',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd-network-fill',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd-rack',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd-rack-fill',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd-stack',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdd-stack-fill',
    tags: ['hard', 'drive', 'hard', 'disk', 'ssd', 'drive', 'server'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdmi',
    tags: ['video', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hdmi-fill',
    tags: ['video', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-headphones',
    tags: ['headphones'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-headset',
    tags: ['headphones', 'microphone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-headset-vr',
    tags: ['virual', 'reality', 'oculus', 'hololens'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heart',
    tags: ['love', 'favorite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heart-arrow',
    tags: ['romance', 'cupid', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heart-fill',
    tags: ['love', 'favorite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heart-half',
    tags: ['love', 'favorite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heart-pulse',
    tags: ['heartbeat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heart-pulse-fill',
    tags: ['heartbeat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heartbreak',
    tags: ['love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heartbreak-fill',
    tags: ['love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hearts',
    tags: ['romance', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heptagon',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heptagon-fill',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-heptagon-half',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hexagon',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hexagon-fill',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hexagon-half',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-highlighter',
    tags: ['text', 'study', 'highlight', 'marker'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-highlights',
    tags: ['photo', 'editing', 'image', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hospital',
    tags: ['health', 'emergency', 'room'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hospital-fill',
    tags: ['health', 'emergency', 'room'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hourglass',
    tags: ['time', 'history', 'wait', 'sand', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hourglass-bottom',
    tags: ['time', 'history', 'wait', 'sand', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hourglass-split',
    tags: ['time', 'history', 'wait', 'sand', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hourglass-top',
    tags: ['time', 'history', 'wait', 'sand', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-add',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-add-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-check',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-check-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-dash',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-dash-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-door',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-door-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-down',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-down-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-exclamation',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-exclamation-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-gear',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-gear-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-heart',
    tags: ['home', 'love', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-heart-fill',
    tags: ['home', 'love', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-lock',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-lock-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-slash',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-slash-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-up',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-up-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-x',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-house-x-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-houses',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-houses-fill',
    tags: ['home'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hr',
    tags: ['divider', 'horizonal-rule'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hurricane',
    tags: ['storm'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-hypnotize',
    tags: ['hypnosis', 'sleepy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-image',
    tags: ['picture', 'photo'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-image-alt',
    tags: ['picture', 'photo'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-image-fill',
    tags: ['picture', 'photo'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-images',
    tags: ['picture', 'photo'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-inbox',
    tags: ['mail', 'email', 'letter', 'tray'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-inbox-fill',
    tags: ['mail', 'email', 'letter', 'tray'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-inboxes-fill',
    tags: ['mail', 'email', 'letter', 'tray'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-inboxes',
    tags: ['mail', 'email', 'letter', 'tray'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-incognito',
    tags: ['private', 'investigator', 'secret'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-indent',
    tags: ['tab', 'indent'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-infinity',
    tags: ['math', 'infinite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-info',
    tags: ['information', 'help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-info-circle',
    tags: ['information', 'help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-info-circle-fill',
    tags: ['information', 'help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-info-lg',
    tags: ['information', 'help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-info-square',
    tags: ['information', 'help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-info-square-fill',
    tags: ['information', 'help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-input-cursor',
    tags: ['textarea', 'form', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-input-cursor-text',
    tags: ['textarea', 'form', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-instagram',
    tags: ['social', 'chat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-intersect',
    tags: ['graphics', 'vector', 'merge', 'layers'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-album',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-arrow-down',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-arrow-up',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-bookmark',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-bookmark-fill',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-check',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-code',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-medical',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-minus',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-plus',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-richtext',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-text',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journal-x',
    tags: ['file', 'folder', 'journal', 'notebook', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-journals',
    tags: ['file', 'folder', 'journal', 'notebook'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-joystick',
    tags: ['gaming', 'game', 'video', 'games'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-justify',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-justify-left',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-justify-right',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-kanban',
    tags: ['board', 'project-management'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-kanban-fill',
    tags: ['board', 'project-management'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-key',
    tags: ['lock', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-key-fill',
    tags: ['lock', 'secure'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-keyboard',
    tags: ['keyboard', 'keys', 'typing'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-keyboard-fill',
    tags: ['keyboard', 'keys', 'typing'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ladder',
    tags: ['climb', 'ladder'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lamp',
    tags: ['light', 'lamp'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lamp-fill',
    tags: ['light', 'lamp'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-laptop',
    tags: ['computer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-laptop-fill',
    tags: ['computer'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layer-backward',
    tags: ['arrange', 'layers', 'back'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layer-forward',
    tags: ['arrange', 'layers', 'front'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layers',
    tags: ['perspective', 'stacked'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layers-fill',
    tags: ['perspective', 'stacked'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layers-half',
    tags: ['perspective', 'stacked'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-sidebar',
    tags: ['grid', 'layout', 'sidebar'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-sidebar-inset-reverse',
    tags: ['layout', 'columns'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-sidebar-inset',
    tags: ['layout', 'columns'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-sidebar-reverse',
    tags: ['grid', 'layout', 'sidebar'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-split',
    tags: ['grid', 'layout', 'sidebar'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-text-sidebar',
    tags: ['layout', 'columns'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-text-sidebar-reverse',
    tags: ['layout', 'columns'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-text-window',
    tags: ['layout', 'columns'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-text-window-reverse',
    tags: ['layout', 'columns'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-three-columns',
    tags: ['layout', 'columns'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-layout-wtf',
    tags: ['layout', 'broken'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-life-preserver',
    tags: ['lifesaver', 'water'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightbulb',
    tags: ['lights', 'lamp'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightbulb-fill',
    tags: ['lights', 'lamp'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightbulb-off',
    tags: ['lights', 'lamp'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightbulb-off-fill',
    tags: ['lights', 'lamp'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightning',
    tags: ['storm', 'thunder', 'bolt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightning-charge',
    tags: ['weather', 'storm', 'thunder', 'bolt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightning-charge-fill',
    tags: ['weather', 'storm', 'thunder', 'bolt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lightning-fill',
    tags: ['storm', 'thunder', 'bolt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-line',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-link',
    tags: ['anchor', 'hyperlink', 'href'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-link-45deg',
    tags: ['anchor', 'hyperlink', 'href'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-linkedin',
    tags: ['social', 'microsoft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list',
    tags: ['text', 'type', 'justify', 'alignment', 'menu', 'hamburger'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-check',
    tags: ['text', 'type', 'justify', 'alignment', 'checklist', 'done'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-columns',
    tags: ['text', 'type', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-columns-reverse',
    tags: ['text', 'type', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-nested',
    tags: ['text', 'type', 'alignment', 'children'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-ol',
    tags: ['text', 'type', 'justify', 'alignment', 'ordered-list', 'numbered-list', 'numbered'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-stars',
    tags: ['text', 'type', 'starred'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-task',
    tags: ['text', 'type', 'justify', 'alignment', 'todos', 'task'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-list-ul',
    tags: ['text', 'type', 'justify', 'alignment', 'unordered-list'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lock',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lock-fill',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-luggage',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-luggage-fill',
    tags: ['luggage', 'bags', 'carry-on', 'student', 'education'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lungs',
    tags: ['medicine', 'health', 'breathing'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-lungs-fill',
    tags: ['medicine', 'health', 'breathing'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-magic',
    tags: ['magic', 'stick'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-magnet',
    tags: ['magnetic'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-magnet-fill',
    tags: ['magnetic'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mailbox',
    tags: ['post', 'postal', 'postbox', 'letterbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mailbox-flag',
    tags: ['post', 'postal', 'postbox', 'letterbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mailbox2',
    tags: ['post', 'postal', 'postbox', 'letterbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mailbox2-flag',
    tags: ['post', 'postal', 'postbox', 'letterbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-map',
    tags: ['geography', 'directions', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-map-fill',
    tags: ['geography', 'directions', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-markdown',
    tags: ['markdown', 'md', 'content', 'writing'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-markdown-fill',
    tags: ['markdown', 'md', 'content', 'writing'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-marker-tip',
    tags: ['text', 'study', 'highlight'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mask',
    tags: ['mask'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mastodon',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-medium',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-megaphone',
    tags: ['loudspeaker', 'announcement'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-megaphone-fill',
    tags: ['loudspeaker', 'announcement'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-memory',
    tags: ['ram'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-app',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-app-fill',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-button',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-button-fill',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-button-wide',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-button-wide-fill',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-down',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-menu-up',
    tags: ['dropdown', 'menu', 'context', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-messenger',
    tags: ['social', 'facebook', 'chat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-meta',
    tags: ['facebook', 'fb'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mic',
    tags: ['audio', 'video', 'av', 'sound', 'input', 'microphone', 'podcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mic-fill',
    tags: ['audio', 'video', 'av', 'sound', 'input', 'microphone', 'podcast'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mic-mute',
    tags: ['audio', 'video', 'av', 'sound', 'input', 'microphone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mic-mute-fill',
    tags: ['audio', 'video', 'av', 'sound', 'input', 'microphone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-microsoft',
    tags: ['msft', 'windows'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-microsoft-teams',
    tags: ['social', 'messaging'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-minecart',
    tags: ['mining', 'cart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-minecart-loaded',
    tags: ['mining', 'cart'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-modem',
    tags: ['internet', 'cable'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-modem-fill',
    tags: ['internet', 'cable'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-moisture',
    tags: ['water', 'droplets'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-moon',
    tags: ['lunar', 'weather', 'night'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-moon-fill',
    tags: ['night', 'sky'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-moon-stars',
    tags: ['night', 'sky'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-moon-stars-fill',
    tags: ['night', 'sky'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mortarboard',
    tags: ['graduation', 'cap'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mortarboard-fill',
    tags: ['graduation', 'cap'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-motherboard',
    tags: ['mobo', 'computer', 'hardware'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-motherboard-fill',
    tags: ['mobo', 'computer', 'hardware'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mouse',
    tags: ['mice', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mouse-fill',
    tags: ['mice', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mouse2',
    tags: ['mice', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mouse2-fill',
    tags: ['mice', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mouse3',
    tags: ['mice', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-mouse3-fill',
    tags: ['mice', 'input'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-music-note',
    tags: ['music', 'notes', 'audio', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-music-note-beamed',
    tags: ['music', 'notes', 'audio', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-music-note-list',
    tags: ['music', 'notes', 'audio', 'sound', 'playlist', 'library'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-music-player',
    tags: ['ipod', 'mp3'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-music-player-fill',
    tags: ['ipod', 'mp3'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-newspaper',
    tags: ['news', 'paper'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-nintendo-switch',
    tags: ['gaming'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-node-minus',
    tags: ['add', 'new', 'node', 'diagram'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-node-minus-fill',
    tags: ['add', 'new', 'node', 'diagram'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-node-plus',
    tags: ['add', 'new', 'node', 'diagram'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-node-plus-fill',
    tags: ['add', 'new', 'node', 'diagram'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-noise-reduction',
    tags: ['photo', 'editing', 'image', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-nut',
    tags: ['nut', 'bolt', 'hexagon', 'tools'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-nut-fill',
    tags: ['nut', 'bolt', 'hexagon', 'tools'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-nvidia',
    tags: ['gpu'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-nvme',
    tags: ['ssd', 'hard', 'drive', 'hard', 'disk', 'storage'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-nvme-fill',
    tags: ['ssd', 'hard', 'drive', 'hard', 'disk', 'storage'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-octagon',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-octagon-fill',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-octagon-half',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-opencollective',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-optical-audio',
    tags: ['port', 'plug', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-optical-audio-fill',
    tags: ['port', 'plug', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-option',
    tags: ['key', 'mac'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-outlet',
    tags: ['plug', 'power'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-p-circle',
    tags: ['parking'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-p-circle-fill',
    tags: ['parking'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-p-square',
    tags: ['parking'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-p-square-fill',
    tags: ['parking'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-paint-bucket',
    tags: ['color', 'paint', 'fill', 'palette'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-palette',
    tags: ['color', 'paint', 'fill', 'palette'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-palette-fill',
    tags: ['color', 'paint', 'fill', 'palette'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-palette2',
    tags: ['color', 'paint', 'fill', 'palette'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-paperclip',
    tags: ['attachment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-paragraph',
    tags: ['paragraph', 'text', 'body', 'content'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pass',
    tags: ['pass'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pass-fill',
    tags: ['pass'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-passport',
    tags: ['visa', 'foreign'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-passport-fill',
    tags: ['visa', 'foreign'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-check',
    tags: ['verified', 'checkmark', 'certified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-check-fill',
    tags: ['verified', 'checkmark', 'certified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-exclamation',
    tags: ['excited', 'certified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-exclamation-fill',
    tags: ['excited', 'certified'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-minus',
    tags: ['subtract'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-minus-fill',
    tags: ['subtract'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-plus',
    tags: ['add'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-plus-fill',
    tags: ['add'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-question',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-patch-question-fill',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pause',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pause-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pause-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pause-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pause-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pause-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-paypal',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pc',
    tags: ['computer', 'workstation', 'desktop'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pc-display',
    tags: ['computer', 'workstation', 'desktop'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pc-display-horizontal',
    tags: ['computer', 'workstation', 'desktop'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pc-horizontal',
    tags: ['computer', 'workstation', 'desktop'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pci-card',
    tags: ['card', 'expansion'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pci-card-network',
    tags: ['card', 'expansion', 'ethernet', 'wifi', 'internet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pci-card-sound',
    tags: ['card', 'expansion', 'audio'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-peace',
    tags: ['peace', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-peace-fill',
    tags: ['peace', 'love'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pen',
    tags: ['edit', 'write', 'ballpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pen-fill',
    tags: ['edit', 'write', 'ballpoint'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pencil',
    tags: ['edit', 'write'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pencil-fill',
    tags: ['edit', 'write'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pencil-square',
    tags: ['edit', 'write'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pentagon',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pentagon-fill',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pentagon-half',
    tags: ['shape', 'polygon'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-people',
    tags: ['member', 'humans', 'organization', 'avatar', 'users', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-circle',
    tags: ['member', 'humans', 'organization', 'avatar', 'user', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-people-fill',
    tags: ['member', 'humans', 'organization', 'avatar', 'users', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-percent',
    tags: ['percentage', 'math', 'fraction'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-add',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-arms-up',
    tags: ['human', 'man', 'woman'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-badge',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'badge', 'id', 'card', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-badge-fill',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'badge', 'id', 'card', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-bounding-box',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'crop', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-check',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'verified', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-check-fill',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'verified', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-dash',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'remove', 'delete', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-dash-fill',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'remove', 'delete', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-down',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-exclamation',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-add',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-check',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-dash',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-down',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-exclamation',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-gear',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-lock',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-slash',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-up',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-fill-x',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-gear',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-heart',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-hearts',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-lines-fill',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'contact', 'list', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-lock',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-plus',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'new', 'add', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-plus-fill',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'new', 'add', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-raised-hand',
    tags: ['human', 'man', 'woman', 'question', 'help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-rolodex',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account', 'contact'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-slash',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-square',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account', 'profile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-standing',
    tags: ['human', 'man', 'woman'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-standing-dress',
    tags: ['human', 'man', 'woman'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-up',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-vcard',
    tags: ['human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-vcard-fill',
    tags: ['human', 'individual', 'avatar', 'user', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-video',
    tags: ['human', 'individual', 'avatar', 'user', 'wfh'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-video2',
    tags: ['human', 'individual', 'avatar', 'user', 'wfh'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-video3',
    tags: ['human', 'individual', 'avatar', 'user', 'wfh'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-walking',
    tags: ['human', 'man', 'woman'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-wheelchair',
    tags: ['human', 'man', 'woman', 'accessibility', 'a11y', 'handicap'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-workspace',
    tags: ['human', 'individual', 'avatar', 'user', 'wfh'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-x',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'remove', 'delete', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-person-x-fill',
    tags: ['member', 'human', 'individual', 'avatar', 'user', 'remove', 'delete', 'account'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-phone',
    tags: ['mobile', 'telephone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-phone-fill',
    tags: ['mobile', 'telephone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-phone-flip',
    tags: ['mobile', 'telephone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-phone-landscape',
    tags: ['mobile', 'telephone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-phone-landscape-fill',
    tags: ['mobile', 'telephone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-phone-vibrate',
    tags: ['mobile', 'telephone', 'haptic'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-phone-vibrate-fill',
    tags: ['mobile', 'telephone', 'haptic'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pie-chart',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pie-chart-fill',
    tags: ['chart', 'graph', 'analytics'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-piggy-bank',
    tags: ['money', 'finance', 'banking', 'savings'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-piggy-bank-fill',
    tags: ['money', 'finance', 'banking', 'savings'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pin',
    tags: ['pushpin', 'thumbtack'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pin-angle',
    tags: ['pushpin', 'thumbtack'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pin-angle-fill',
    tags: ['pushpin', 'thumbtack'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pin-fill',
    tags: ['pushpin', 'thumbtack'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pin-map',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pin-map-fill',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pinterest',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pip',
    tags: ['picture', 'tv', 'television', 'display', 'nested'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-pip-fill',
    tags: ['picture', 'tv', 'television', 'display', 'nested'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-play',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-play-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-play-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-play-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-play-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-play-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-playstation',
    tags: ['sony', 'gaming'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plug',
    tags: ['power', 'outlet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plug-fill',
    tags: ['power', 'outlet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plugin',
    tags: ['addon', 'software'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-circle',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-circle-dotted',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-circle-fill',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-lg',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-slash-minus',
    tags: ['difference', 'add', 'subtract'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-square',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-square-dotted',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-plus-square-fill',
    tags: ['add', 'new'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postage',
    tags: ['stamp', 'shipping'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postage-fill',
    tags: ['mail', 'stamp', 'shipping'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postage-heart',
    tags: ['mail', 'stamp', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postage-heart-fill',
    tags: ['mail', 'stamp', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postcard',
    tags: ['mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postcard-fill',
    tags: ['mail', 'letter'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postcard-heart',
    tags: ['mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-postcard-heart-fill',
    tags: ['mail', 'letter', 'love', 'valentine', 'romance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-power',
    tags: ['false', 'true'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-prescription',
    tags: ['rx', 'pills', 'capsules', 'medicine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-prescription2',
    tags: ['rx', 'pills', 'capsules', 'medicine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-printer',
    tags: ['print'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-printer-fill',
    tags: ['print'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-projector',
    tags: ['projection', 'present', 'screen'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-projector-fill',
    tags: ['projection', 'present', 'screen'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-puzzle',
    tags: ['puzzle', 'piece'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-puzzle-fill',
    tags: ['puzzle', 'piece'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-qr-code',
    tags: ['scan'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-qr-code-scan',
    tags: ['scan'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-circle',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-diamond',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-diamond-fill',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-circle-fill',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-lg',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-octagon',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-octagon-fill',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-square',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-question-square-fill',
    tags: ['help'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-quora',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-quote',
    tags: ['blockquote', 'quotes', 'quotation'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-r-circle',
    tags: ['registered', 'trademark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-r-circle-fill',
    tags: ['registered', 'trademark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-r-square',
    tags: ['registered', 'trademark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-r-square-fill',
    tags: ['registered', 'trademark'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-radar',
    tags: ['geography', 'map', 'pin', 'location'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-radioactive',
    tags: ['radiation', 'nuclear', 'danger'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rainbow',
    tags: ['colors', 'arches'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-receipt',
    tags: ['receipt', 'invoice', 'sale', 'purchase', 'bill'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-receipt-cutoff',
    tags: ['receipt', 'invoice', 'sale', 'purchase', 'bill'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reception-0',
    tags: ['reception', 'cellphone', 'mobile', 'carrier', 'network', 'signal'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reception-1',
    tags: ['reception', 'cellphone', 'mobile', 'carrier', 'network', 'signal'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reception-2',
    tags: ['reception', 'cellphone', 'mobile', 'carrier', 'network', 'signal'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reception-3',
    tags: ['reception', 'cellphone', 'mobile', 'carrier', 'network', 'signal'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reception-4',
    tags: ['reception', 'cellphone', 'mobile', 'carrier', 'network', 'signal'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record2',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-record2-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-recycle',
    tags: ['recyling', 'trash'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reddit',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-regex',
    tags: ['text', 'type', 'code', 'developer', 'development', 'software', 'regular', 'expression'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-repeat',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-repeat-1',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reply',
    tags: ['mail', 'email'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reply-all',
    tags: ['mail', 'email'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reply-all-fill',
    tags: ['mail', 'email'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-reply-fill',
    tags: ['mail', 'email'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rewind',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rewind-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rewind-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rewind-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rewind-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rewind-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-robot',
    tags: ['bot'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rocket',
    tags: ['ship', 'rocketship', 'spaceship'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rocket-fill',
    tags: ['ship', 'rocketship', 'spaceship'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rocket-takeoff',
    tags: ['ship', 'rocketship', 'spaceship', 'launch'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rocket-takeoff-fill',
    tags: ['ship', 'rocketship', 'spaceship', 'launch'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-router',
    tags: ['wifi', 'internet', 'wireless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-router-fill',
    tags: ['wifi', 'internet', 'wireless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rss',
    tags: ['atom', 'feed', 'xml'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rss-fill',
    tags: ['atom', 'feed', 'xml'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-rulers',
    tags: ['measure', 'guide'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-safe',
    tags: ['vault', 'bank', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-safe-fill',
    tags: ['vault', 'bank', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-safe2',
    tags: ['vault', 'bank', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-safe2-fill',
    tags: ['vault', 'bank', 'finance'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-save',
    tags: ['save', 'floppy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-save-fill',
    tags: ['save', 'floppy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-save2',
    tags: ['save', 'floppy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-save2-fill',
    tags: ['save', 'floppy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-scissors',
    tags: ['cut', 'shears'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-scooter',
    tags: ['riding'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-screwdriver',
    tags: ['tool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sd-card',
    tags: ['storage', 'microsd', 'chip', 'memory'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sd-card-fill',
    tags: ['storage', 'microsd', 'chip', 'memory'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-search',
    tags: ['magnifying-glass', 'look'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-search-heart',
    tags: ['magnifying-glass', 'look', 'love', 'romance', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-search-heart-fill',
    tags: ['magnifying-glass', 'look', 'love', 'romance', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-segmented-nav',
    tags: ['nav', 'tabs', 'tabbed', 'app', 'ui'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-arrow-down',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-arrow-down-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-arrow-up',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-arrow-up-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-check',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-check-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-dash',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-dash-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-exclamation',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-exclamation-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-plus',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-plus-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-slash',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-slash-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-x',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-send-x-fill',
    tags: ['message', 'sending', 'sent', 'paper-plane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-server',
    tags: ['server', 'network'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shadows',
    tags: ['photo', 'editing', 'image', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-share',
    tags: ['share', 'link'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-share-fill',
    tags: ['share', 'link'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-check',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-exclamation',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-fill',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-fill-check',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-fill-exclamation',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-fill-minus',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-fill-plus',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-fill-x',
    tags: ['privacy', 'security', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-lock',
    tags: ['privacy', 'security', 'lock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-lock-fill',
    tags: ['privacy', 'security', 'lock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-minus',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-plus',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-shaded',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-slash',
    tags: ['shield', 'badge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-slash-fill',
    tags: ['shield', 'badge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shield-x',
    tags: ['privacy', 'security', 'remove', 'delete'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shift',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shift-fill',
    tags: ['key'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shop',
    tags: ['shop', 'store', 'market', 'marketplace', 'shopping', 'retail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shop-window',
    tags: ['shop', 'store', 'market', 'marketplace', 'shopping', 'retail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-shuffle',
    tags: ['shuffle', 'random'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-dead-end',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-dead-end-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-do-not-enter',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-do-not-enter-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection-side',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection-side-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection-t',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection-t-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection-y',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-intersection-y-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-merge-left',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-merge-left-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-merge-right',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-merge-right-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-no-left-turn',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-no-left-turn-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-no-parking',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-no-parking-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-no-right-turn',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-no-right-turn-fill',
    tags: ['road', 'driving', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-railroad',
    tags: ['road', 'driving', 'train', 'tracks'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-railroad-fill',
    tags: ['road', 'driving', 'train', 'tracks'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-stop',
    tags: ['stop', 'sign', 'intersection', 'road', 'driving'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-stop-fill',
    tags: ['stop', 'sign', 'intersection', 'road', 'driving'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-stop-lights',
    tags: ['stop', 'sign', 'intersection', 'road', 'driving'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-stop-lights-fill',
    tags: ['stop', 'sign', 'intersection', 'road', 'driving'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-left',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-left-fill',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-right',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-right-fill',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-slight-left',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-slight-left-fill',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-slight-right',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-turn-slight-right-fill',
    tags: ['road', 'driving', 'navigate', 'navigation', 'route'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-yield',
    tags: ['road', 'driving', 'intersection'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sign-yield-fill',
    tags: ['road', 'driving', 'intersection'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-signal',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-signpost',
    tags: ['milestone', 'sign', 'road', 'sign', 'street', 'sign', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-signpost-2',
    tags: ['milestone', 'sign', 'road', 'sign', 'street', 'sign', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-signpost-2-fill',
    tags: ['milestone', 'sign', 'road', 'sign', 'street', 'sign', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-signpost-fill',
    tags: ['milestone', 'sign', 'road', 'sign', 'street', 'sign', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-signpost-split',
    tags: ['milestone', 'sign', 'road', 'sign', 'street', 'sign', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-signpost-split-fill',
    tags: ['milestone', 'sign', 'road', 'sign', 'street', 'sign', 'directions'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sim',
    tags: ['mobile', 'carrier'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sim-fill',
    tags: ['mobile', 'carrier'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sim-slash',
    tags: ['mobile', 'carrier'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sim-slash-fill',
    tags: ['mobile', 'carrier'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sina-weibo',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-backward',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-backward-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-backward-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-backward-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-backward-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-backward-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-end',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-end-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-end-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-end-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-end-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-end-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-forward',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-forward-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-forward-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-forward-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-forward-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-forward-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-start',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-start-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-start-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-start-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-start-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skip-start-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-skype',
    tags: ['social', 'microsoft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-slack',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-slash',
    tags: ['shape', 'stop', 'ban', 'false'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-slash-circle-fill',
    tags: ['shape', 'stop', 'ban', 'false'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-slash-lg',
    tags: ['shape', 'stop', 'ban', 'false'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-slash-square',
    tags: ['shape', 'stop', 'ban', 'false'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-slash-square-fill',
    tags: ['shape', 'stop', 'ban', 'false'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sliders',
    tags: ['equalizer', 'settings', 'preferences', 'dials'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sliders2',
    tags: ['equalizer', 'settings', 'preferences', 'dials'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sliders2-vertical',
    tags: ['equalizer', 'settings', 'preferences', 'dials'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-smartwatch',
    tags: ['watch', 'wearables'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-snapchat',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-snow',
    tags: ['blizzard', 'flurries'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-snow2',
    tags: ['blizzard', 'flurries'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-snow3',
    tags: ['blizzard', 'flurries'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-alpha-down',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-alpha-down-alt',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-alpha-up',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-alpha-up-alt',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-down',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-down-alt',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-numeric-down',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-numeric-down-alt',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-numeric-up',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-numeric-up-alt',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-up',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sort-up-alt',
    tags: ['sort', 'filter', 'organize'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-soundwave',
    tags: ['audio', 'sound', 'wave'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sourceforge',
    tags: ['social', 'vcs', 'git'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-speaker',
    tags: ['audio', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-speaker-fill',
    tags: ['audio', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-speedometer',
    tags: ['speed', 'tachometer', 'dashboard', 'gauge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-speedometer2',
    tags: ['speed', 'tachometer', 'dashboard', 'gauge'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-spellcheck',
    tags: ['spelling', 'grammar'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-spotify',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-square',
    tags: ['shape', 'rectangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-square-fill',
    tags: ['shape', 'rectangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-square-half',
    tags: ['shape', 'rectangle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stack',
    tags: ['layers'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stack-overflow',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-star',
    tags: ['shape', 'like', 'favorite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-star-fill',
    tags: ['shape', 'like', 'favorite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-star-half',
    tags: ['shape', 'like', 'favorite'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stars',
    tags: ['clear', 'skies', 'night'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-steam',
    tags: ['gaming'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stickies',
    tags: ['postit', 'note', 'sticky'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stickies-fill',
    tags: ['postit', 'note', 'sticky'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sticky',
    tags: ['postit', 'note', 'sticky'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sticky-fill',
    tags: ['postit', 'note', 'sticky'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stop',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stop-btn',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stop-btn-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stop-circle',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stop-circle-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stop-fill',
    tags: ['audio', 'video', 'av'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stoplights',
    tags: ['traffic', 'lights', 'intersection'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stoplights-fill',
    tags: ['traffic', 'lights', 'intersection'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stopwatch',
    tags: ['time', 'timer', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stopwatch-fill',
    tags: ['time', 'timer', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-strava',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-stripe',
    tags: ['payments', 'commerce'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-subscript',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-substack',
    tags: ['social', 'blog'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-subtract',
    tags: ['graphics', 'vector', 'merge', 'layers'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-club',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-club-fill',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-diamond',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-diamond-fill',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-heart',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-heart-fill',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-spade',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suit-spade-fill',
    tags: ['card', 'cards', 'suit', 'deck', 'gambling'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suitcase',
    tags: ['luggage', 'bags', 'carry-on'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suitcase-fill',
    tags: ['luggage', 'bags', 'carry-on'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suitcase-lg',
    tags: ['luggage', 'bags', 'carry-on'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suitcase-lg-fill',
    tags: ['luggage', 'bags', 'carry-on'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suitcase2',
    tags: ['luggage', 'bags', 'carry-on'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-suitcase2-fill',
    tags: ['luggage', 'bags', 'carry-on'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sun',
    tags: ['solar', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sun-fill',
    tags: ['solar', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sunglasses',
    tags: ['shades', 'cool', 'aviators'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sunrise',
    tags: ['dawn'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sunrise-fill',
    tags: ['dawn'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sunset',
    tags: ['dusk'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-sunset-fill',
    tags: ['dusk'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-superscript',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-symmetry-horizontal',
    tags: ['align', 'orientation', 'mirror'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-symmetry-vertical',
    tags: ['align', 'orientation', 'mirror'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-table',
    tags: ['spreadsheet'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tablet',
    tags: ['mobile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tablet-fill',
    tags: ['mobile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tablet-landscape',
    tags: ['mobile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tablet-landscape-fill',
    tags: ['mobile'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tag',
    tags: ['price', 'category', 'taxonomy', 'label'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tag-fill',
    tags: ['price', 'category', 'taxonomy', 'label'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tags',
    tags: ['price', 'category', 'taxonomy', 'label'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tags-fill',
    tags: ['price', 'category', 'taxonomy', 'label'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-taxi-front',
    tags: ['cab', 'uber', 'lyft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-taxi-front-fill',
    tags: ['cab', 'uber', 'lyft'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telegram',
    tags: ['social', 'chat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-fill',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-forward',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-forward-fill',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-inbound',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-inbound-fill',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-minus',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-minus-fill',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-outbound',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-outbound-fill',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-plus',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-plus-fill',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-x',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-telephone-x-fill',
    tags: ['telephone', 'phone', 'call'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tencent-qq',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-terminal',
    tags: ['command-line', 'cli', 'command-prompt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-terminal-dash',
    tags: ['command-line', 'cli', 'command-prompt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-terminal-fill',
    tags: ['command-line', 'cli', 'command-prompt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-terminal-plus',
    tags: ['command-line', 'cli', 'command-prompt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-terminal-split',
    tags: ['command-line', 'cli', 'command-prompt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-terminal-x',
    tags: ['command-line', 'cli', 'command-prompt'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-text-center',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-text-indent-left',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-text-indent-right',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-text-left',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-text-paragraph',
    tags: ['text', 'type', 'paragraph', 'copy'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-text-right',
    tags: ['text', 'type', 'justify', 'alignment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-text-wrap',
    tags: ['text', 'type', 'word', 'wrap'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-textarea',
    tags: ['text', 'insert', 'bounding-box'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-textarea-resize',
    tags: ['text', 'form'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-textarea-t',
    tags: ['text', 'insert', 'bounding-box'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thermometer',
    tags: ['temperature', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thermometer-half',
    tags: ['temperature', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thermometer-high',
    tags: ['temperature', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thermometer-low',
    tags: ['temperature', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thermometer-snow',
    tags: ['temperature', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thermometer-sun',
    tags: ['temperature', 'weather'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-threads',
    tags: ['social', 'chat', 'instagram', 'meta'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-threads-fill',
    tags: ['social', 'chat', 'instagram', 'meta'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-three-dots',
    tags: ['meatballs', 'more', 'ellipsis', 'overflow', 'menu'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-three-dots-vertical',
    tags: ['kebab', 'more', 'ellipsis', 'overflow', 'menu'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thunderbolt',
    tags: ['plug', 'port'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-thunderbolt-fill',
    tags: ['plug', 'port'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ticket',
    tags: ['tickets', 'admission'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ticket-detailed',
    tags: ['tickets', 'admission'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ticket-detailed-fill',
    tags: ['tickets', 'admission'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ticket-fill',
    tags: ['tickets', 'admission'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ticket-perforated',
    tags: ['tickets', 'admission'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ticket-perforated-fill',
    tags: ['tickets', 'admission'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tiktok',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-toggle-off',
    tags: ['toggle', 'switch', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-toggle-on',
    tags: ['toggle', 'switch', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-toggle2-off',
    tags: ['toggle', 'switch', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-toggle2-on',
    tags: ['toggle', 'switch', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-toggles',
    tags: ['toggle', 'switch', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-toggles2',
    tags: ['toggle', 'switch', 'checkbox'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tools',
    tags: ['tool', 'wrench', 'screwdriver'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tornado',
    tags: ['wind'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-train-freight-front',
    tags: ['transit', 'public', 'rail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-train-freight-front-fill',
    tags: ['transit', 'public', 'rail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-train-front',
    tags: ['transit', 'public', 'rail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-train-front-fill',
    tags: ['transit', 'public', 'rail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-train-lightrail-front',
    tags: ['transit', 'public', 'rail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-train-lightrail-front-fill',
    tags: ['transit', 'public', 'rail'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-translate',
    tags: ['translation', 'languages', 'language'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-transparency',
    tags: ['photo', 'editing', 'image', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trash',
    tags: ['trash-can', 'garbage', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trash-fill',
    tags: ['trash-can', 'garbage', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trash2',
    tags: ['trash-can', 'garbage', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trash2-fill',
    tags: ['trash-can', 'garbage', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trash3',
    tags: ['trash-can', 'garbage', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trash3-fill',
    tags: ['trash-can', 'garbage', 'delete', 'remove'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tree',
    tags: ['tree', 'forrest'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tree-fill',
    tags: ['tree', 'forrest'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trello',
    tags: ['kanban', 'board'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-triangle',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-triangle-fill',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-triangle-half',
    tags: ['shape'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trophy',
    tags: ['prize', 'winning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-trophy-fill',
    tags: ['prize', 'winning'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tropical-storm',
    tags: ['hurricane'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-truck',
    tags: ['trucking', 'shipping', 'shipment', 'transport', 'deliver', 'delivery', 'vehicle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-truck-flatbed',
    tags: ['trucking', 'shipping', 'shipment', 'transport', 'deliver', 'delivery', 'vehicle'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-truck-front',
    tags: ['vehicle', 'shipping', 'transit', 'haul', 'freight'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-truck-front-fill',
    tags: ['vehicle', 'shipping', 'transit', 'haul', 'freight'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tsunami',
    tags: ['wave'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tv',
    tags: ['television', 'monitor', 'display'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-tv-fill',
    tags: ['television', 'monitor', 'display'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-twitch',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-twitter',
    tags: ['social', 'chat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-twitter-x',
    tags: ['social', 'chat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-bold',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-h1',
    tags: ['text', 'type', 'heading'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-h2',
    tags: ['text', 'type', 'heading'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-h3',
    tags: ['text', 'type', 'heading'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-h4',
    tags: ['text', 'type', 'heading'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-h5',
    tags: ['text', 'type', 'heading'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-h6',
    tags: ['text', 'type', 'heading'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-italic',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-strikethrough',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-type-underline',
    tags: ['text', 'type'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ubuntu',
    tags: ['linux', 'distro'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ui-checks',
    tags: ['checkbox', 'form'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ui-checks-grid',
    tags: ['checkbox', 'form'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ui-radios',
    tags: ['radio', 'form'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-ui-radios-grid',
    tags: ['radio', 'form'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-umbrella',
    tags: ['rain'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-umbrella-fill',
    tags: ['rain'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-unindent',
    tags: ['tab', 'indent'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-union',
    tags: ['graphics', 'vector', 'merge', 'layers'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-unity',
    tags: ['gaming'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-universal-access',
    tags: ['accessibility', 'a11y'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-universal-access-circle',
    tags: ['accessibility', 'a11y'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-unlock',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-unlock-fill',
    tags: ['privacy', 'security'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-upc',
    tags: ['purchase', 'barcode'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-upc-scan',
    tags: ['purchase', 'barcode'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-upload',
    tags: ['arrow', 'network'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-c',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-c-fill',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-drive',
    tags: ['thumb-drive'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-drive-fill',
    tags: ['thumb-drive'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-fill',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-micro',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-micro-fill',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-mini',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-mini-fill',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-plug',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-plug-fill',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-usb-symbol',
    tags: ['port', 'plug'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-valentine',
    tags: ['love', 'romance', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-valentine2',
    tags: ['love', 'romance', 'valentine'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-vector-pen',
    tags: ['graphics', 'vector', 'pen'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-view-list',
    tags: ['view', 'rearrange'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-view-stacked',
    tags: ['view', 'rearrange'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-vignette',
    tags: ['photo', 'editing', 'image', 'picture'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-vimeo',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-vinyl',
    tags: ['audio', 'music', 'record'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-vinyl-fill',
    tags: ['audio', 'music', 'record'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-virus',
    tags: ['covid', 'viral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-virus2',
    tags: ['covid', 'viral'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-voicemail',
    tags: ['voicemail', 'message', 'telephone'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-down',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-down-fill',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-mute',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-mute-fill',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-off',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-off-fill',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-up',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-volume-up-fill',
    tags: ['audio', 'video', 'av', 'sound'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-vr',
    tags: ['divider', 'vertical-rule'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wallet',
    tags: ['cards', 'money', 'funds', 'cash', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wallet-fill',
    tags: ['cards', 'money', 'funds', 'cash', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wallet2',
    tags: ['cards', 'money', 'funds', 'cash', 'payment'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-watch',
    tags: ['wearables', 'clock'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-water',
    tags: ['waves', 'levels'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-webcam',
    tags: ['camera', 'video'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-webcam-fill',
    tags: ['camera', 'video'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wechat',
    tags: ['social', 'messaging'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-whatsapp',
    tags: ['social', 'facebook', 'chat'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wifi',
    tags: ['internet', 'network', 'wireless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wifi-1',
    tags: ['internet', 'network', 'wireless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wifi-2',
    tags: ['internet', 'network', 'wireless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wifi-off',
    tags: ['internet', 'network', 'wireless'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wikipedia',
    tags: ['encyclopedia'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wind',
    tags: ['windy', 'breeze'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window',
    tags: ['app', 'application'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-dash',
    tags: ['application', 'desktop', 'app'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-desktop',
    tags: ['application', 'desktop', 'app'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-dock',
    tags: ['application', 'desktop', 'os'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-fullscreen',
    tags: ['application', 'desktop', 'app'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-plus',
    tags: ['application', 'desktop', 'app'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-sidebar',
    tags: ['application', 'desktop'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-split',
    tags: ['application', 'desktop', 'app'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-stack',
    tags: ['application', 'desktop', 'app', 'windows'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-window-x',
    tags: ['application', 'desktop', 'app'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-windows',
    tags: ['microsoft', 'msft', 'windows'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wordpress',
    tags: ['social'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wrench',
    tags: ['tool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wrench-adjustable',
    tags: ['tool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wrench-adjustable-circle',
    tags: ['tool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-wrench-adjustable-circle-fill',
    tags: ['tool'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-circle',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-circle-fill',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-diamond',
    tags: ['x', 'delete', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-diamond-fill',
    tags: ['x', 'delete', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-lg',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-octagon',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-octagon-fill',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-square',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-x-square-fill',
    tags: ['x', 'delete', 'remove', 'reset', 'clear', 'cancel', 'close', 'exit'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-xbox',
    tags: ['microsoft', 'gaming'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-yelp',
    tags: ['social', 'reviews'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-yin-yang',
    tags: ['peace'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-youtube',
    tags: ['social', 'video', 'google'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-zoom-in',
    tags: ['magnify', 'scale'],
    hidden: false,
    active: false
  },
  {
    class: 'bi-zoom-out',
    tags: ['minify', 'scale'],
    hidden: false,
    active: false
  }
];
