<template>
  <span>{{ loading }}</span>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'LoadingComp',

  setup() {
    const loading = ref(null);
    loading.value = '.';
    setInterval(() => {
      if (!loading.value) loading.value = '.';
      else {
        if (loading.value.length === 4) loading.value = '.';
        else loading.value += '.';
      }
    }, 500);
    return {
      loading
    };
  }
};
</script>

<style></style>
