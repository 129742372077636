<template>
  <div class="todo-container">
    <div class="main-todo-container">
      <input
        ref="newTodoInput"
        type="text"
        class="form-control text-light"
        id="new-todo-input"
        @keydown.enter="addTodo"
        placeholder="New To-Do" />
      <div class="todo-controls-container mobile-content">
        <button @click="addTodo" class="btn green">
          <i class="icon bi-clipboard-plus"></i>
          <span>Add</span>
        </button>
        <button @click="deleteTodo('all')" class="btn red">
          <i class="icon bi-trash"></i>
          <span>Clear</span>
        </button>
      </div>
      <ul class="todo-list">
        <DraggableComp
          v-model="todosInStorage"
          handle=".todo-item-move-btn"
          @start="drag = true"
          @end="drag = false"
          item-key="index">
          <template #item="{ element, index }">
            <li class="todo-item">
              <div class="todo-item-move-btn">
                <i class="icon bi-arrows-move"></i>
              </div>
              <div class="todo-item-value-container">
                <div
                  @input="editTodo($event, index)"
                  @keydown.enter="blurTodo($event)"
                  contenteditable="true"
                  class="todo-item-value">
                  {{ element.value }}
                </div>
              </div>
              <div @click="deleteTodo(index)" class="todo-item-delete-btn">
                <i class="icon bi-trash"></i>
              </div>
            </li>
          </template>
        </DraggableComp>
      </ul>
    </div>
    <div class="todo-controls-container">
      <button @click="addTodo" class="btn green">
        <i class="icon bi-clipboard-plus"></i>
        <span>Add</span>
      </button>
      <button @click="deleteTodo('all')" class="btn red">
        <i class="icon bi-trash"></i>
        <span>Clear</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import DraggableComp from 'vuedraggable';
export default {
  components: { DraggableComp },
  setup() {
    const newTodoInput = ref(null);
    const todosInStorage = ref([]);

    todosInStorage.value = JSON.parse(localStorage.getItem('todos')) ?? [];

    const addTodo = () => {
      if (newTodoInput.value.value) {
        const todos = JSON.parse(localStorage.getItem('todos')) ?? [];
        todos.unshift({ value: newTodoInput.value.value });
        todosInStorage.value = todos;
        newTodoInput.value.value = '';
      }
    };

    const deleteTodo = index => {
      if (index === 'all') {
        todosInStorage.value = [];
        return;
      }
      const todos = JSON.parse(localStorage.getItem('todos'));
      todos.splice(index, 1);
      todosInStorage.value = todos;
    };

    const editTodo = function (event, index) {
      const todos = JSON.parse(localStorage.getItem('todos'));
      todos[index].value = event.target.textContent;
      todosInStorage.value = todos;
    };

    const blurTodo = event => {
      event.target.blur();
    };

    watch(todosInStorage, (newTodosInStorage, oldTodosInStorage) => {
      localStorage.setItem('todos', JSON.stringify(newTodosInStorage));
    });

    return {
      newTodoInput,
      todosInStorage,
      addTodo,
      deleteTodo,
      editTodo,
      blurTodo
    };
  }
};
</script>

<style>
.todo-container {
  display: flex;
  max-width: 600px;
  width: 100%;
}

.main-todo-container {
  width: 100%;
}

#new-todo-input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
}

.todo-controls-container {
  margin-left: 10px;
}

.todo-controls-container .btn {
  height: 40px;
  width: 80px;
}

.todo-controls-container .btn .icon {
  margin-right: 4px;
}

.todo-controls-container .btn:first-child {
  margin-bottom: 6px;
}

.todo-list {
  padding: 0;
  list-style: none;
  margin-top: 10px;
}

.todo-list .todo-item {
  display: flex;
  font-size: 14px;
  word-break: break-word;
}

.todo-list .todo-item:not(:last-child) {
  margin-bottom: 8px;
}

.todo-item .todo-item-value-container {
  width: 100%;
  background-color: #484f64;
  border: 1px solid #999797;
  /* border-radius: 4px 0px 0px 4px; */
}

.todo-item .todo-item-value-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
  outline: none;
  border-radius: 0;
  border: none;
}

.todo-item .todo-item-value-container:hover {
  border: 1px solid white;
}

.todo-item .todo-item-value-container:focus-within {
  border: 1px solid white;
}

.todo-item .todo-item-value {
  outline: none;
  padding: 8px 6px;
}

.todo-item .todo-item-value:focus {
  color: white;
}

.todo-item .todo-item-delete-btn {
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #999797;
  border-left: none;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--red-color);
}

.todo-item .todo-item-delete-btn:hover {
  cursor: pointer;
  background-color: var(--red-color-hover);
}

.todo-item .todo-item-move-btn {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #999797;
  border-right: none;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-blue-color);
}

.todo-item .todo-item-move-btn:hover {
  cursor: move;
  background-color: var(--main-blue-color-hover);
}

.mobile-content.todo-controls-container {
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.mobile-content.todo-controls-container .btn:first-child {
  margin: 0px 6px 0 0;
}

@media screen and (max-width: 600px) {
  .mobile-content.todo-controls-container {
    display: flex;
  }

  .todo-controls-container {
    display: none;
  }
}
</style>
