<template>
  <div class="icons-container">
    <div class="icons-search-container">
      <input @input="findIcons($event)" type="text" class="icons-search-input" placeholder="Search icons..." />
    </div>
    <div class="icons-list-container">
      <div
        v-for="(icon, index) in iconsList"
        :key="index"
        v-show="!icon.hidden"
        @click="copyIconClass(index)"
        class="card icon-container"
        :class="{ active: icon.active }"
        :title="icon.class">
        <i :class="icon.class" class="icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/helpers/icons';
import { ref } from 'vue';
export default {
  setup() {
    const iconsList = ref(icons);
    const iconsResetNeeded = ref(false);

    const resetIcons = () => {
      for (const iconIndex in iconsList.value) iconsList.value[iconIndex].hidden = false;
      iconsResetNeeded.value = false;
    };

    const findIcons = event => {
      if (iconsResetNeeded.value) resetIcons();
      const query = event.target.value.toLowerCase();
      iconsResetNeeded.value = true;

      for (const iconIndex in iconsList.value) {
        let hidden = true;
        if (iconsList.value[iconIndex].class.includes(query)) hidden = false;
        for (const iconTag of iconsList.value[iconIndex].tags) {
          if (iconTag.includes(query)) {
            hidden = false;
            break;
          }
        }
        iconsList.value[iconIndex].hidden = hidden;
      }
    };

    const copyIconClass = iconIndex => {
      navigator.clipboard.writeText(iconsList.value[iconIndex].class);
      iconsList.value[iconIndex].active = true;
      setTimeout(() => {
        iconsList.value[iconIndex].active = false;
      }, 300);
    };

    return {
      iconsList,
      findIcons,
      copyIconClass
    };
  }
};
</script>

<style>
.icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.icons-container .icon {
  font-size: 30px;
  width: 30px;
  height: 30px;
}

.icons-container .icon-container {
  line-height: 100%;
  width: 45px;
  height: 45px;
  box-sizing: border-box;
}

.icons-container .icon-container:hover {
  border: 1px solid white;
  cursor: pointer;
}

.icons-search-container {
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
}

.icons-search-input {
  width: 100%;
  box-sizing: border-box;
}

.icons-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.icons-container .icon-container.active {
  border-color: var(--main-blue-color-active);
}
</style>
