// return true if user is authenticated. false otherwise
const userIsAuthenticated = async () => {
  let isAuth = false;
  const res = await $axios.get('/auth/check');
  if (res.status === 200 && res.data) isAuth = res.data.isAuth;
  return isAuth;
};

// try to log in and return login status
const login = async pass => {
  let loginStatus = false;
  const res = await $axios.post('/auth/login', { pass });
  if (res.status === 200 && res.data) loginStatus = res.data.loginStatus;
  return loginStatus;
};

export default {
  userIsAuthenticated,
  login
};
