<template>
  <div id="main-navbar">
    <div id="nav-title-container">
      <!-- <router-link to="/" id="nav-title-link"><h3 id="nav-title">JSMETHOD</h3></router-link> -->
    </div>
    <div class="mobile-nav-btn">
      <i @click="showMobileNav = !showMobileNav" id="mobile-nav-toggle-btn" class="icon bi-list"></i>
    </div>
    <ul class="nav-links-list">
      <li v-for="link in navbarLinks" :key="link.title" class="nav-item">
        <router-link :to="link.href" class="nav-link">{{ link.title }}</router-link>
      </li>
    </ul>
  </div>
  <Transition name="slide-down">
    <div id="mobile-nav" v-if="showMobileNav">
      <ul class="mobile-nav-links-list">
        <li v-for="link in navbarLinks" :key="link.title" class="nav-item">
          <router-link @click.self="showMobileNav = false" :to="link.href" class="nav-link">
            {{ link.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </Transition>
  <Transition name="slide-up">
    <div id="mobile-nav-background" v-if="showMobileNav" @click.self="showMobileNav = false"></div>
  </Transition>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'NavbarComp',
  setup() {
    const navbarLinks = [
      { title: 'Home', href: '/' },
      { title: 'Downloads', href: '/downloads' },
      { title: 'To-Do', href: '/to-do' },
      { title: 'Lorem', href: '/lorem' },
      // { title: 'Icons', href: '/icons' },
      { title: 'Logs', href: '/logs' }
    ];

    const showMobileNav = ref(false);

    return { navbarLinks, showMobileNav };
  }
};
</script>

<style>
#mobile-nav-background {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  transform-origin: bottom;
  z-index: 1;
}

.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-down-enter-from,
.slide-down-leave-to,
.slide-up-enter-from,
.slide-up-leave-to {
  transform: scaleY(0);
}

#mobile-nav {
  background-color: var(--main-blue-color);
  width: 100%;
  transform-origin: top;
  position: fixed;
  z-index: 2;

  /* transition: transform .4s ease-in-out; */
}

#mob-nav-back.active {
  display: block;
}

#mob-nav ul {
  list-style: none;
}

#mob-nav .nav-link {
  color: inherit;
  text-decoration: none;
}

#main-navbar {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  background-color: var(--main-blue-color);
  color: var(--light-white-color);
}

#mobile-nav {
  color: var(--light-white-color);
}

.mobile-nav-btn {
  line-height: 24px;
  height: 24px;
}
#nav-title-link {
  text-decoration: none;
  color: inherit;
}

#nav-title-container {
  display: inline-flex;
}

#nav-title {
  margin: 0;
}

.nav-links-list {
  display: inline-flex;
  list-style: none;
  margin: 0;
}

.mobile-nav-links-list {
  list-style: none;
  margin: 0;
}

#main-navbar .nav-item + .nav-item {
  margin-left: 10px;
}

.nav-links-list .nav-link {
  color: inherit;
  text-decoration: none;
}

.nav-links-list .nav-link:hover {
  text-decoration: underline 1px solid;
  text-underline-offset: 4px;
}

.mobile-nav-links-list .nav-link {
  color: inherit;
  text-decoration: none;
}

.mobile-nav-links-list .nav-link:hover {
  text-decoration: underline 1px solid;
  text-underline-offset: 4px;
}

.mobile-nav-links-list .nav-item:not(:last-child) {
  margin-bottom: 10px;
}

#mobile-nav {
  padding: 10px 0 14px 0;
}

#mobile-nav-toggle-btn {
  display: none;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: var(--light-white-color);
}

@media screen and (max-width: 400px) {
  #mobile-nav-toggle-btn {
    display: block;
  }

  .nav-links-list {
    display: none;
  }
}
</style>
