<template>
  <div class="downloads-container">
    <div class="main-download-container">
      <div class="video-url-input-container">
        <input autocomplete="off" ref="videoUrlInput" id="video-url-input" class="input" type="text" />
        <button
          :disabled="isGettingVideoData || isPreparingFormatsDownload"
          @click="getVideoData"
          id="get-formats-btn"
          class="btn blue">
          <LoadingComp v-if="isGettingVideoData" />
          <span v-else>Get</span>
        </button>
      </div>
      <div v-if="videoData.id" class="video-data-container">
        <div class="general-info-container">
          <div class="thumb-container">
            <img width="160" :src="videoData.thumb" />
          </div>
          <div class="title-container">
            <div class="video-title">{{ videoData.title }}</div>
            <div class="video-channel">{{ videoData.channel }}</div>
            <div class="video-duration">{{ videoData.duration }}</div>
          </div>
        </div>
        <div>
          <ul class="video-formats-list">
            <li
              @click="chooseFormat(row.formatId)"
              class="video-format format"
              :class="{
                disabled: row.disabled,
                'selected video': selectedFormats.video === row.formatId,
                'selected audio': selectedFormats.audio === row.formatId
              }"
              v-for="(row, index) in videoData.formats"
              :key="index"
              :data-index="index">
              <span>{{ row.format }}</span>
              <span class="format-info-separator">&nbsp;|&nbsp;</span>
              <span>
                <span>{{ row.ext }}</span>
                <span>&nbsp;(</span>
                <span v-if="row.vcodec" class="video-format-value">{{ row.vcodec }}</span>
                <template v-if="row.vcodec && row.acodec">&nbsp;+&nbsp;</template>
                <span v-if="row.acodec" class="audio-format-value">{{ row.acodec }}</span>
                <span>)</span>
                <template v-if="row.filesize && row.filesize.size">
                  <span class="format-info-separator">&nbsp;|</span>
                  <span>&nbsp;{{ row.filesize.size }} {{ row.filesize.measure }}</span>
                </template>
              </span>
            </li>
          </ul>
        </div>
        <div class="card format-selectors-container">
          <div
            class="format-selector"
            :class="{
              active: activeFormatSelector === 'video'
            }"
            data-type="video">
            <span class="video-format-value">Video</span>
            <button
              @click="formatSelectorAction('video')"
              :disabled="isPreparingFormatsDownload || isGettingVideoData"
              class="btn choose-format-btn">
              <span>{{ selectedFormats.video ?? '...' }}</span>
            </button>
          </div>
          <div
            class="format-selector"
            :class="{
              active: activeFormatSelector === 'audio'
            }"
            data-type="audio">
            <span class="audio-format-value">Audio</span>
            <button
              @click="formatSelectorAction('audio')"
              :disabled="isPreparingFormatsDownload || isGettingVideoData"
              class="btn choose-format-btn">
              <span>{{ selectedFormats.audio ?? '...' }}</span>
            </button>
          </div>
          <button
            @click="download"
            :disabled="isPreparingFormatsDownload || isGettingVideoData"
            class="btn"
            :class="{ 'is-preparing-formats-download': isPreparingFormatsDownload }"
            id="download-formats-btn">
            <LoadingComp v-if="isPreparingFormatsDownload" />
            <span v-else>
              <span class="btn-main-content">Download</span>
              <i class="icon bi-download btn-mobile-content"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="existing-downloads-list" ref="existingDownloadsList">
      <div
        class="existing-download-file card"
        v-for="file in existingDownloads"
        :key="file.id"
        :class="{ 'in-progress': !file.ready }">
        <div class="title-container">
          <span>{{ file.name }}</span>
          <div v-if="file.ready" class="controls-container">
            <a @click="deleteFile(file.id)">
              <i class="icon bi-trash"></i>
            </a>
            <a :download="file.name + file.ext" :href="file.path">
              <i class="icon bi-download"></i>
            </a>
          </div>
        </div>
        <div class="download-progress-container" v-if="!file.ready">
          <span>Downloading</span>
          <LoadingComp />
        </div>
        <div class="stats-container">
          <template v-if="file.ready">
            <div class="file-stat">
              <span>ext:</span>
              <span>{{ file.ext }}</span>
            </div>
            <div class="file-stat">
              <span>size:</span>
              <span>{{ file.size.size }} {{ file.size.measure }}</span>
            </div>
          </template>
          <div class="file-stat">
            <span>time:</span>
            <span>{{ formatFileDate(file.createDatetime) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { inject, watch } from '@vue/runtime-core';
import moment from 'moment';
import LoadingComp from '@/components/LoadingComp.vue';

export default {
  name: 'DownloadView',
  components: { LoadingComp },
  setup() {
    const existingDownloads = ref([]); // existing downloads
    const videoUrlInput = ref(null); // input for video url
    const videoUrl = ref(null); // url of a video to fetch
    const videoData = ref({}); // after fetching, video data is stored here
    const activeFormatSelector = ref(null); // user-pressed format selector. either video or audio
    const selectedFormats = ref({ video: null, audio: null }); // user-selected formats for download
    const isGettingVideoData = ref(false); // true if fetching video data at the moment
    const isPreparingFormatsDownload = ref(false); // true if preparing for formats download at the moment
    const isDownloadingFormats = ref(false); // true if some formats are downloading at the moment
    const checkDownloadsInterval = ref(null); // is used to update existing downloads when isDownloadingFormats is true
    const existingDownloadsList = ref(null); // the DOM element containing existing downloads

    // download selected formats
    // append new files to downloads
    const download = () => {
      isPreparingFormatsDownload.value = true;
      resetFormatSelectors();
      const downloadData = {
        url: videoUrl.value,
        videoId: videoData.value.id,
        name: videoData.value.title,
        formats: selectedFormats.value
      };

      if (
        !downloadData.url ||
        !downloadData.videoId ||
        (!selectedFormats.value.video && !selectedFormats.value.audio)
      ) {
        isPreparingFormatsDownload.value = false;
        return;
      }

      $axios
        .post('/downloads/download', {
          downloadData
        })
        .then(res => {
          // console.log(res.data);
        })
        .catch(err => console.error(err))
        .then(() => {
          getExistingDownloads();
          isPreparingFormatsDownload.value = false;
          $smoothScroll({
            scrollTo: existingDownloadsList.value,
            offset: -16,
            duration: 500
          });
        });
    };

    // enable all formats in the list
    const enableAllFormats = () => {
      for (const formatId in videoData.value.formats) videoData.value.formats[formatId].disabled = false;
    };

    // enable all formats
    // deactivate active format selector
    const resetFormatSelectors = () => {
      enableAllFormats();
      activeFormatSelector.value = null;
    };

    // make format selector inactive again
    // mark selected format with some color
    const chooseFormat = formatId => {
      if (!activeFormatSelector.value) return;

      const format = videoData.value.formats[formatId];
      if (format.type !== activeFormatSelector.value) return;

      // if the same format is selected, remove it from selected formats
      if (formatId === selectedFormats.value[format.type]) selectedFormats.value[format.type] = null;
      else selectedFormats.value[format.type] = formatId;

      resetFormatSelectors();
    };

    // user pressed format selector button to choose a format
    // make it active and disable wrong formats
    const formatSelectorAction = selectorType => {
      // if selector already active, deactivate it and enable all formats again
      if (activeFormatSelector.value === selectorType) {
        resetFormatSelectors();
        return;
      }
      for (const formatId in videoData.value.formats) {
        const format = videoData.value.formats[formatId];
        videoData.value.formats[formatId].disabled = format.type !== selectorType ? true : false;
      }
      activeFormatSelector.value = selectorType;
    };

    // fetch already downloaded files and show them on the page
    const getExistingDownloads = () => {
      $axios
        .get('/downloads/existingdownloads')
        .then(res => {
          existingDownloads.value = res.data.downloads;
          isDownloadingFormats.value = res.data.isDownloading;
        })
        .catch(err => console.error(err));
    };

    // show video info and format selection after fetching
    const loadVideoDataOnPage = data => {
      videoData.value = {};
      videoData.value.formats = {};

      for (const f of data.formats) {
        let type = null;
        if (f.video_ext && f.video_ext !== 'none') type = 'video';
        if (f.audio_ext && f.audio_ext !== 'none') type = 'audio';
        if (!type) continue;
        if ((!f.vcodec || f.vcodec === 'none') && (!f.acodec || f.acodec === 'none')) continue;
        videoData.value.formats[f.format_id] = {
          formatId: f.format_id,
          format: f.format,
          filesize: f.filesize ? formatFileSize(f.filesize) : null,
          ext: f.ext,
          type: type,
          vcodec: f.vcodec && f.vcodec !== 'none' ? f.vcodec : null,
          acodec: f.acodec && f.acodec !== 'none' ? f.acodec : null,
          disabled: false
        };
      }

      videoData.value.id = data.id;
      videoData.value.title = data.title;
      videoData.value.channel = data.channel;
      videoData.value.thumb = data.thumbnail;
      videoData.value.duration = data.duration_string;
    };

    // fetch video data by url
    const getVideoData = () => {
      isGettingVideoData.value = true;
      resetFormatSelectors();
      videoUrl.value = videoUrlInput.value.value;
      if (!videoUrl.value) videoUrl.value = 'https://www.youtube.com/watch?v=SolaR4ohJPU';
      selectedFormats.value.video = null;
      selectedFormats.value.audio = null;

      // $axios
      //   .get('/get.json')
      //   .then(res => {
      //     if (!res.data.videoData.formats || !Object.keys(res.data.videoData.formats).length) {
      //       console.error({ err: 'Error getting video data!', url: videoUrl.value });
      //       return;
      //     }
      //     loadVideoDataOnPage(res.data.videoData);
      //   })
      //   .catch(err => console.error(err))
      //   .then(() => {
      //     getExistingDownloads();
      //     isGettingVideoData.value = false;
      // });

      $axios
        .post('/downloads/getvideodata', { url: videoUrl.value ?? '' })
        .then(res => {
          if (!res.data.videoData.formats || !Object.keys(res.data.videoData.formats).length) {
            console.error({ err: 'Error getting video data!', url: videoUrl.value });
            return;
          }
          loadVideoDataOnPage(res.data.videoData);
        })
        .catch(err => console.error(err))
        .then(() => {
          getExistingDownloads();
          isGettingVideoData.value = false;
        });
    };

    // delete downloaded file
    const deleteFile = fileId => {
      $axios
        .post('/downloads/delete', { fileId })
        .then(res => {
          getExistingDownloads();
        })
        .catch(err => console.error(err));
    };

    // format file size
    const formatFileSize = size => {
      let returnSize = { size, measure: 'B' };
      const measures = ['B', 'KB', 'MB', 'GB'];

      for (let i = 0; i < measures.length; i++) {
        let p = i + 1;
        if (size / Math.pow(1024, p) < 1) {
          returnSize.size = Math.round((size / Math.pow(1024, p - 1)) * 100) / 100;
          returnSize.measure = measures[i];
          break;
        }
      }

      return returnSize;
    };

    // format file date
    const formatFileDate = date => {
      return moment(date).local().format('HH:mm:ss DD.MM.YYYY');
    };

    // watch for on-going downloads and spam requests to update stuff
    watch(isDownloadingFormats, isDownloadingFormats => {
      clearInterval(checkDownloadsInterval.value);
      if (isDownloadingFormats) {
        checkDownloadsInterval.value = setInterval(() => {
          getExistingDownloads();
        }, 3000);
      }
    });

    getExistingDownloads(); // show existing downloads on page load

    return {
      getVideoData,
      existingDownloads,
      deleteFile,
      videoData,
      videoUrlInput,
      chooseFormat,
      formatSelectorAction,
      selectedFormats,
      download,
      videoUrl,
      activeFormatSelector,
      isGettingVideoData,
      isPreparingFormatsDownload,
      existingDownloadsList,
      formatFileDate
    };
  }
};
</script>

<style>
#get-formats-btn {
  margin-left: 6px;
  min-width: 50px;
  height: 30px;
}

.existing-download-file .controls-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.existing-download-file .controls-container a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.existing-download-file .controls-container a:hover {
  cursor: pointer;
}

.existing-download-file .controls-container .icon {
  color: var(--main-blue-color-active);
}

.existing-download-file .controls-container .icon:hover {
  color: var(--main-blue-color-hover);
}

.video-data-container .title-container .video-channel,
.video-data-container .title-container .video-duration {
  font-size: 14px;
}

.existing-downloads-list {
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  width: 100%;
}

.existing-download-file {
  width: calc(50% - 6px);
  box-sizing: border-box;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.existing-download-file.in-progress {
  border-color: var(--light-pink-color);
}

.main-download-container {
  width: 100%;
}

.existing-download-file .title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.existing-download-file .title-container .controls-container {
  margin-left: 4px;
}

.existing-download-file .stats-container .file-stat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.existing-download-file .download-progress-container {
  font-size: 14px;
  color: var(--light-pink-color);
}

.video-format pre {
  margin: 0;
}

.video-format.format:hover {
  background-color: var(--dark-blue);
  border: 1px solid white;
  cursor: pointer;
}

.video-format {
  border: 1px solid transparent;
  padding: 2px 6px;
  border-radius: 4px;
}

.video-format.disabled:not(.selected) {
  cursor: default;
  opacity: 0.5;
}

.video-url-input-container {
  display: flex;
  justify-content: space-between;
}

.video-url-input-container,
.video-data-container {
  width: 100%;
}

.video-data-container {
  margin-top: 16px;
}

.video-formats-list {
  max-height: 400px;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.download-link {
  display: block;
  text-align: center;
  margin-top: 4px;
}

.format-selector {
  display: flex;
  align-items: center;
}

.format-selector[data-type='video'] {
  margin-right: 20px;
}

.format-selector[data-type='audio'] {
  margin-right: 30px;
}

.format-selector.active .choose-format-btn {
  background-color: var(--main-blue-color-active);
}

.format-selectors-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  padding: 6px 18px;
}

.choose-format-btn {
  margin-left: 6px;
  height: 24px;
  width: 50px;
  min-width: fit-content;
}

.video-format-value {
  color: var(--light-pink-color);
}

.video-format.selected.video {
  background-color: var(--light-pink-color);
}

.video-format.selected.video:hover {
  background-color: var(--light-pink-color);
}

.audio-format-value {
  color: var(--light-green-color);
}

.video-format.selected.audio {
  background-color: var(--light-green-color);
}

.video-format.selected.audio:hover {
  background-color: var(--light-green-color);
}

#video-url-input {
  width: inherit;
  position: relative; /* breaks fonts without this (https://bugs.chromium.org/p/chromium/issues/detail?id=1423434) */
  height: 30px;
  box-sizing: border-box;
}

.general-info-container {
  display: flex;
}

.general-info-container .thumb-container {
  margin-right: 20px;
}

.general-info-container {
  margin-bottom: 8px;
}

.video-formats-list .format {
  font-size: 13px;
  font-family: monospace;
}

.downloads-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}

#download-formats-btn {
  height: 30px;
}

#download-formats-btn.is-preparing-formats-download {
  min-width: 40px;
}

#download-formats-btn .btn-main-content {
  display: block;
}

#download-formats-btn .btn-mobile-content {
  display: none;
}

.video-formats-list .format.selected .video-format-value,
.video-formats-list .format.selected .audio-format-value {
  color: inherit;
}

@media screen and (max-width: 600px) {
  .existing-download-file {
    width: 100%;
  }

  .video-format .format-info-separator {
    display: none;
  }

  .video-formats-list .video-format {
    display: flex;
    flex-direction: column;
  }

  /* .video-format:nth-child(even) {
    background-color: #1c3040;
  } */
}

@media screen and (max-width: 400px) {
  .format-selector[data-type='video'],
  .format-selector[data-type='audio'] {
    margin-right: 0;
  }

  .format-selectors-container {
    justify-content: space-around;
    padding: 6px;
  }

  #download-formats-btn {
    min-width: 40px;
  }

  #download-formats-btn .btn-main-content {
    display: none;
  }

  #download-formats-btn .btn-mobile-content {
    display: block;
  }
}
</style>
