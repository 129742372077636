<template>
  <!-- test -->
  <!-- <CarouselComp></CarouselComp> -->
</template>

<script>
import CarouselComp from '@/components/CarouselComp.vue';
export default {
  setup() {
    console.log(1);
  },
  components: { CarouselComp }
};
</script>

<style></style>
