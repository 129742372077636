<template>
  <div id="logs-view-container">
    <div v-show="showNavigation" id="page-navigation-container">
      <button @click="cleanLogFile" @keydown.enter.prevent.stop class="btn">
        <i class="icon bi-trash"></i>
      </button>
      <button @click="navigate('top')" @keydown.enter.prevent.stop class="btn">
        <i class="icon bi-arrow-up"></i>
      </button>
      <button @click="refreshLogData()" @keydown.enter.prevent.stop title="(Enter, R) on keyboard" class="btn">
        <i class="icon bi-arrow-down"></i>
      </button>
    </div>
    <div class="log-files-navigation-container">
      <div v-for="(file, index) in logFiles" :key="index" class="log-file-name">
        <router-link @click="refreshLogData(file)" :to="`/logs/?file=${file}`" class="log-file-link">
          {{ file }}
        </router-link>
      </div>
    </div>

    <div class="logs-container">
      <div v-for="(log, index) in logData" :key="index" class="log-container card">
        <span class="log-time">{{ formatFileDate(log.timestamp) }}</span>
        <div class="log-message">
          {{ log.stack ?? log.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';
export default {
  setup() {
    const route = useRoute(); // route info
    const logFiles = ref([]); // all existing log files
    const logData = ref([]); // contents of the currently selected log file
    const showNavigation = ref(false); // show/hide navigation buttons

    // load all existing log files to .log-files-navigation-container
    const loadLogFiles = async () => {
      $axios
        .get(`/logs`)
        .then(res => {
          logFiles.value = res.data.files;
        })
        .catch(err => console.error(err));
    };

    // load contents of the currently selected log file
    const loadLogData = async () => {
      $axios
        .get(`/logs/?file=${route.query.file}`)
        .then(res => {
          logData.value = res.data.logData;
        })
        .catch(err => console.error(err))
        .then(() => {
          updateNavigationState();
        });
    };

    const refreshLogData = file => {
      // file is passed if clicked from navigation links (.log-files-navigation-container)
      // if clicked file is different from the one opened now, don't do anything
      if (file && file !== route.query.file) return;
      // if clicked on navigation button (#page-navigation-container), reload current log file and navigate to bottom
      loadLogData();
      if (!file) navigate('bottom');
    };

    // format file date
    const formatFileDate = date => {
      return moment(date).local().format('HH:mm:ss DD.MM.YYYY');
    };

    // smooth scroll to top/bottom of the page
    const navigate = where => {
      $smoothScroll({
        scrollTo: where === 'top' ? 0 : document.documentElement.scrollHeight,
        duration: 300
      });
    };

    // show/hide #page-navigation-container depending on current log file
    const updateNavigationState = () => {
      showNavigation.value = logData.value.length;
      // && document.documentElement.scrollHeight > document.documentElement.clientHeight;
    };

    // clean log file contents
    const cleanLogFile = () => {
      const file = route.query.file;
      if (!file) return;
      if (confirm(`Clean ${file}?`)) {
        $axios
          .post('/logs/delete', { file })
          .then(res => {
            loadLogData();
          })
          .catch(err => console.error(err));
      }
    };

    loadLogFiles();
    if (route.query.file) loadLogData();

    // watch for url change to load/unload log file
    watch(
      () => route.query.file,
      async newFile => {
        if (newFile) loadLogData();
        else {
          logData.value = [];
          updateNavigationState();
        }
      }
    );

    // Enter or R to scroll down
    const handleLogDataRefresh = event => {
      if (event.key === 'Enter' || event.keyCode === 82) refreshLogData();
    };

    // show/hide #page-navigation-container depending on current window dimensions
    const handleWindowResize = event => updateNavigationState();

    // apply event listeners
    document.addEventListener('keyup', handleLogDataRefresh);
    window.addEventListener('resize', handleWindowResize);

    // remove event listeners before navigating to another page
    onBeforeUnmount(() => {
      document.removeEventListener('keyup', handleLogDataRefresh);
      window.removeEventListener('resize', handleWindowResize);
    });

    return {
      logFiles,
      logData,
      showNavigation,
      formatFileDate,
      navigate,
      loadLogData,
      refreshLogData,
      cleanLogFile,
    };
  }
};
</script>

<style>
#logs-view-container {
  width: 100%;
}

.log-files-navigation-container {
  margin-bottom: 10px;
}

.log-file-link {
  color: var(--light-pink-color);
  text-decoration: none;
}

.log-file-link:hover {
  text-decoration: underline 1px solid;
  text-underline-offset: 4px;
}

.log-file-name {
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
}

.log-file-link {
  margin-right: 4px;
}

.delete-log-file-btn {
  color: var(--main-blue-color-active);
}

.delete-log-file-btn:hover {
  color: var(--main-blue-color-hover);
  cursor: pointer;
}

.log-container {
  padding: 6px 10px;
}

.log-container:not(:last-child) {
  margin-bottom: 10px;
}

.log-time {
  color: var(--main-blue-color-active);
}

.log-message {
  word-break: break-word;
  font-size: 14px;
  white-space: pre-wrap;
}

#page-navigation-container {
  width: fit-content;
  right: 20px;
  bottom: 30px;
  position: fixed;
}

#page-navigation-container .btn {
  background-color: hsl(208, 87%, 35%, 20%);
}

#page-navigation-container .btn:hover {
  cursor: pointer;
  background-color: hsl(208, 87%, 35%, 100%);
}

#page-navigation-container .btn:not(:last-child) {
  margin-bottom: 4px;
}

@media screen and (max-width: 400px) {
  .log-file-name {
    display: flex;
  }

  .log-file-name:not(:last-child) {
    margin-bottom: 4px;
  }
}
</style>
