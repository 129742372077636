import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/helpers/auth';
import HomeView from '@/views/HomeView.vue';
import DownloadsView from '@/views/DownloadsView.vue';
import ToDoView from '@/views/ToDoView.vue';
import LoremView from '@/views/LoremView.vue';
import IconsView from '@/views/IconsView.vue';
import LogsView from '@/views/LogsView.vue';
import PortfolioMainView from '@/views/portfolio/PortfolioMainView';
import PortfolioLandingView from '@/views/portfolio/PortfolioLandingView';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: DownloadsView,
    meta: {
      title: 'Downloads'
    }
  },
  {
    path: '/to-do',
    name: 'todo',
    component: ToDoView,
    meta: {
      title: 'To-Do'
    }
  },
  {
    path: '/lorem',
    name: 'lorem',
    component: LoremView,
    meta: {
      title: 'Lorem'
    }
  },
  {
    path: '/icons',
    name: 'icons',
    component: IconsView,
    meta: {
      title: 'Icons'
    }
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView,
    meta: {
      title: 'Logs',
      secure: 1
    }
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: PortfolioMainView,
    meta: {
      title: 'Portfolio'
      // hideMainNavbar: 1
    }
  },
  {
    path: '/portfolio/landing',
    name: 'portfolio-landing',
    component: PortfolioLandingView,
    meta: {
      title: 'Landing',
      hideMainNavbar: 1

    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach(async (to, from) => {
  if (to.meta.secure) {
    const allowAccess = await auth.userIsAuthenticated();
    if (!allowAccess) {
      const password = prompt('PASSWORD?!!');
      if (password) {
        const loginStatus = await auth.login(password);
        if (!loginStatus) return router.replace(from);
      } else {
        return router.replace(from);
      }
    }
  }

  document.title = to.meta.title + ' - jsmethod';
});

export default router;
