<template>
  <NavbarComp v-if="!route.meta.hideMainNavbar" />
  <div class="centered-container">
    <router-view />
  </div>
</template>

<script>
import NavbarComp from '@/components/NavbarComp.vue';
import axios from '@/helpers/axios';
import { inject } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: { NavbarComp },

  setup() {
    const route = useRoute();
    window.$axios = axios;
    window.$cookies = inject('$cookies');
    window.$smoothScroll = inject('smoothScroll'); // smooth scroll yay

    return {
      route
    };
  }
};
</script>
