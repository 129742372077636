<template>
  <div class="port-nav">
    <template v-for="link in links" :key="link.title">
      <router-link :to="link.href">{{ link.title }}</router-link>
    </template>
  </div>
</template>

<script>
export default {
  setup() {
    const links = [
      { title: 'Landing', href: 'landing' },
    ];

    return { links };
  }
};
</script>

<style>
.port-nav {
  display: flex;
  flex-direction: column;
}
</style>
