import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vue3SmoothScroll from 'vue3-smooth-scroll';
import VueCookies from 'vue-cookies';

const app = createApp(App);
app.use(router);
app.use(Vue3SmoothScroll, {
  updateHistory: false
  //   scrollTo: element/number
  //   hash: '#sampleHash'  // required if updateHistory is true
  //   duration: 500,       // animation duration in ms
  //   offset: 0,           // offset in px from scroll element, can be positive or negative
  //   container: '',       // selector string or Element for scroll container, default is window
  //   updateHistory: true, // whether to push hash to history
  //   easingFunction: null // gives the ability to provide a custom easing function `t => ...`
  //                        // (see https://gist.github.com/gre/1650294 for examples)
  //                        // if nothing is given, it will defaults to `easeInOutCubic`
});
app.use(VueCookies);
app.mount('#app');
